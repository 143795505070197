

//@ts-nocheck
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
// import SubHeader, {
// 	SubHeaderLeft,
// 	SubHeaderRight,
// 	SubheaderSeparator,
// } from '../../../layout/SubHeader/SubHeader';
import Icon from '../../../components/icon/Icon';
import Badge from '../../../components/bootstrap/Badge';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../components/bootstrap/Card';


import userState from '../../../shared/mobx/user-state';
import Button from '../../../components/bootstrap/Button';

import Avatar, { AvatarGroup } from '../../../components/Avatar';
import USERS from '../../../common/data/userDummyData';

import Progress from '../../../components/bootstrap/Progress';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import { use } from 'i18next';
import UserPdfHistory from '../users/userPdfHistory';
import DateRange from './DateRange';
import moment, { Moment } from 'moment';
import styles from '../../../user/styles/adminStyle.module.css'

const ItemNew: FC<IItemNewProps> = ({
    name,
    teamName,
    attachCount,
    taskCount,
    percent,
    dueDate,
    ...props
}) => {
    const { darkModeStatus } = false;

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className='col-md-3' {...props}>
            <Card className='cursor-pointer' stretch>
                <CardHeader>
                    <CardLabel>
                        <CardTitle>{teamName}</CardTitle>
                        {/* <CardSubTitle>{teamName}</CardSubTitle> */}
                    </CardLabel>
                    {/* <CardActions>
						<small className='border border-success border-2 text-success fw-bold px-2 py-1 rounded-1'>
							{dueDate}
						</small>
					</CardActions> */}
                </CardHeader>
                <CardBody>
                    <div className='row mb-3'>
                        <div className='col-12 text-center'>
                            <div className='ratio ratio-1x1 m-auto' style={{ width: 100 }}>
                                <div
                                    className={classNames(
                                        'rounded-2',
                                        'd-flex align-itemNews-center justify-content-center',
                                        'bg-l10-info'
                                    )}>
                                    <span className='text-info fs-1 fw-bold my-auto'>{attachCount}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        {/* <div className='col-md-12'>
                            {percent}%
                            <Progress isAutoColor value={percent} height={10} />
                        </div> */}
                        {/* <div className='col-md-6 d-flex justify-content-end'>
							<AvatarGroup>
								<Avatar
									srcSet={USERS.GRACE.srcSet}
									src={USERS.GRACE.src}
									userName={`${USERS.GRACE.name} ${USERS.GRACE.surname}`}
									color={USERS.GRACE.color}
								/>
								<Avatar
									srcSet={USERS.SAM.srcSet}
									src={USERS.SAM.src}
									userName={`${USERS.SAM.name} ${USERS.SAM.surname}`}
									color={USERS.SAM.color}
								/>
								<Avatar
									srcSet={USERS.CHLOE.srcSet}
									src={USERS.CHLOE.src}
									userName={`${USERS.CHLOE.name} ${USERS.CHLOE.surname}`}
									color={USERS.CHLOE.color}
								/>

								<Avatar
									srcSet={USERS.JANE.srcSet}
									src={USERS.JANE.src}
									userName={`${USERS.JANE.name} ${USERS.JANE.surname}`}
									color={USERS.JANE.color}
								/>
								<Avatar
									srcSet={USERS.JOHN.srcSet}
									src={USERS.JOHN.src}
									userName={`${USERS.JOHN.name} ${USERS.JOHN.surname}`}
									color={USERS.JOHN.color}
								/>
								<Avatar
									srcSet={USERS.RYAN.srcSet}
									src={USERS.RYAN.src}
									userName={`${USERS.RYAN.name} ${USERS.RYAN.surname}`}
									color={USERS.RYAN.color}
								/>
							</AvatarGroup>
						</div> */}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

const UserDashboard = () => {
    const [data, setData] = useState<any>({});
    const [cardData, setCardData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const { darkModeStatus } = false;
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let dates = {}
            if (startDate && endDate) {
                dates['startDate'] = moment(startDate).format('YYYY-MM-DD')
                dates['endDate'] = moment(endDate).format('YYYY-MM-DD')
            }
            const data_ = await userState.getUserStats(dates);
            setLoading(false);
            if (data_) {
                const tempD = [
                    // PDF generation statistics
                    {
                        name: "PDF Generation Stats",
                        description: "Today",
                        class: "light-purple dashboard",
                        count: data_.totalPDFGeneratedToday,
                        icon: "Today" // Icon that directly represents "Today"
                    },
                    {
                        name: "PDF Generation Stats",
                        description: "This Week",
                        class: "dark-purple dashboard",
                        count: data_.totalPDFGeneratedThisWeek,
                        icon: "DateRange" // Icon representing a range of days, ideal for "This Week"
                    },
                    {
                        name: "PDF Generation Stats",
                        description: "This Month",
                        class: "light-purple dashboard",
                        count: data_.totalPDFGeneratedThisMonth,
                        icon: "EventNote" // Icon that represents an event or record, suitable for "This Month"
                    },
                    {
                        name: "PDF Generation Stats",
                        description: "Total",
                        class: "dark-purple dashboard",
                        count: data_.totalPDFGenerated,
                        icon: "InsertDriveFile" // Icon for "Total" as it suggests an accumulation or collection of files
                    },
                ];




                console.log(tempD);
                setCardData(tempD);

            }

        }
        fetchData();
    }, [endDate]);
    return (
        <>
            <div className='row mt-3'>
                {/* <div className='col-12'>
                    <div className='display-6 fw-bold py-2'>Dashboard</div>
                </div> */}
                <DateRange setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
                <div className="col-sm-12">
                    <Card
                        className={classNames('transition-base rounded-2 mb-0 text-dark')}
                        stretch
                        shadow='sm'>
                        <CardHeader className='bg-transparent'>
                            <CardLabel>
                                <CardTitle tag='div' className='h5'>
                                    PDF Generated
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <LoaderWrapper isLoading={loading} data={cardData}>
                                <div className="row">
                                    {cardData?.map(d => (
                                        <div className="col-sm-3">
                                            <Card
                                                stretch
                                                className={classNames('transition-base rounded-2 mb-4 text-dark', {
                                                    [d.class]: true
                                                })}
                                                shadow='sm'>
                                                <CardHeader className='bg-transparent'>
                                                    <CardLabel>
                                                        <CardTitle tag='div' className='h5'>
                                                            {d.description}
                                                        </CardTitle>
                                                    </CardLabel>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className='d-flex align-items-center pb-3'>
                                                        <div className='flex-shrink-0'>
                                                            <Icon icon={d.icon || 'ConfirmationNumber'} size='4x' color='primary' />
                                                        </div>
                                                        <div className='flex-grow-1 ms-3'>
                                                            <div className='fw-bold fs-3 mb-0'>
                                                                {d.count}
                                                                {/* <PercentComparison
                        valueOne={sales.coupons.price}
                        valueTwo={sales.coupons.compare}
                    /> */}
                                                            </div>
                                                            <div
                                                                className={classNames({
                                                                    'text-muted': !darkModeStatus,
                                                                    'text-light': darkModeStatus,
                                                                })}>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    ))

                                    }
                                </div>



                            </LoaderWrapper>
                        </CardBody>
                    </Card>
                </div>

            </div>
            <UserPdfHistory startDate={startDate} endDate={endDate} />
        </>
    );
};

export default UserDashboard;
