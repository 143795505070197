// @ts-nocheck
import { configure, makeAutoObservable, autorun, IReactionDisposer } from 'mobx';
import { apiClient } from '../../shared/base-classes/apiBaseClass';
import notificationState from '../../shared/mobx/notification-state';
import auth from "../../shared/mobx/auth-state";
import subscriptionState from '../../shared/mobx/subscription-state';

enum Status {
    Idle = 'idle',
    Started = 'started',
    InProcess = 'pending',
    Completed = 'complete',
    Failed = 'failed',
    SiteMapGenerated = 'sitemapegenrated'
}

interface MyData {
    [key: string]: any;
}

class PdfServiceStore {
    data: MyData = {};
    status?: Status;
    message?: string;
    errorMsg?: string;
    timerId: NodeJS.Timeout | null = null;
    progress: number = 0;
    is_separate = false;
    form: MyData = { target_url: '' };
    apiClient!: typeof apiClient;
    statusCheckDisposer?: IReactionDisposer;
    bulkSeperateResponse = []
    constructor() {
        this.apiClient = apiClient;
        makeAutoObservable(this);
        configure({ enforceActions: 'never' });
    }

    handleChange(e) {
        this.form[e.target.name] = e.target.value
    }

    get isLoading() {
        return this.status === Status.InProcess;
    }

    get isGenerated() {
        return !this.isLoading && (this.is_separate || this.data?.pdfUrl);
    }


    async userPdfHistory(config, dates) {
        try {

            let params = '';
            if (dates.startDate && dates.endDate) {
                params = `startDate=${dates.startDate}&endDate=${dates.endDate}`
            }
            const response = await this.apiClient.get(`userpdfhistory?per_page=${config.per_page || 10}&page=${config.page}&${params}`);
            return response.data;
        } catch (e: any) {
            notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
        }
    }
    startStatusCheck() {
        this.statusCheckDisposer = autorun(() => {
            if (this.status === Status.InProcess) {
                this.timerId = setInterval(() => {
                    this.checkStatus();
                }, 10000);
            } else {
                this.stopStatusCheck();
            }
        });
    }

    stopStatusCheck() {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
        if (this.statusCheckDisposer) {
            this.statusCheckDisposer();
        }
    }

    async checkStatus() {

        try {
            const response = await this.apiClient.post("checkpdfstatus",
                { request_token: this.data.request_token, is_separate: this.is_separate })
            //Status based check for process,  in process | complete otherwise failed
            // if (response?.data?.status !== Status.InProcess || response?.data.every(e => e.status !== Status.InProcess)) {
            //     this.stopStatusCheck();
            //     this.form.target_url = "";
            // }

            if (Array.isArray(response?.data) && response?.data?.every(e => e.status !== Status.InProcess)) {
                this.stopStatusCheck();
                this.form.target_url = "";
            } else if (!Array.isArray(response?.data) && response?.data?.status !== Status.InProcess) {
                this.stopStatusCheck();
                this.form.target_url = "";
            }

            if (response?.data?.status === Status.Completed || (
                Array.isArray(response?.data) && response?.data?.every(e => e.status === Status.Completed)
            )) {
                this.data.pdfUrl = response.data.pdfPath;
                this.status = Status.Completed;
                this.bulkSeperateResponse = response?.data || [];
                notificationState.addToasterSuccess(response.message || 'PDF generation complete!')
            } else if (response?.data.status === Status.Failed || (
                Array.isArray(response?.data) && response?.data?.every(e => e.status !== Status.InProcess)
            )) {
                this.status = Status.Failed;
                this.bulkSeperateResponse = response?.data || [];
                notificationState.addToasterError(response.message || "Pdf generation failed...");
            }

        } catch (e: any) {
            this.status = Status.Failed;
            this.stopStatusCheck();

            notificationState.addToasterError(e.message || "Pdf generation failed...");
        }
    }

    resetState() {
        this.progress = 0;
        this.status = Status.Idle;
        this.form.target_url = "";
        this.is_separate = false;
        if (this.data?.pdfUrl) {
            this.data.pdfUrl = "";
        }
    }

    async generateSiteMap(target_url?: string) {
        try {
            this.progress = 0;
            let data = null;
            this.status = Status.InProcess;
            const response = await this.apiClient.post('generate-sitemap', { target_url });
            this.status = Status.SiteMapGenerated;
            if (response.status) {
                data = response.data;
                this.message = response.data.message;
                notificationState.addToasterSuccess(response!.message || 'Process started...');
            } else {
                notificationState.addToasterError(response.message ?? 'Something went wrong, try again');
                this.status = Status.Failed;
            }
            return data;
        } catch (e: any) {
            this.status = Status.Failed;
            this.errorMsg = e.response?.data?.errors[0] || e.message;
            if (e?.response?.status === 403) {
                notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
                this.navigate("/subscriptions");
            } else {
                notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
            }
        }
    }

    async convertToPdf(target_url?: string, options = {}) {
        try {
            this.bulkSeperateResponse = []
            this.progress = 0;
            this.status = Status.InProcess;
            const response = await this.apiClient.post(
                auth.isUser && subscriptionState.hasSubscription ? 'converttopdf'
                    : 'converttopdfunpaid', { target_url, ...options });
            console.log(response);
            if (response.status) {
                this.data = response.data;
                this.message = response.data.message;
                notificationState.addToasterSuccess(response!.message || 'Process started...');
                this.startStatusCheck();
            } else {
                if (response.errors && response.errors.length) {
                    response.errors?.forEach(m => {
                        notificationState.addToasterError(m);
                    });
                } else {
                    notificationState.addToasterError(response.message ?? 'Something went wrong, try again');
                }


                this.status = Status.Failed;
            }
            return this.data;
        } catch (e: any) {
            // alert('in error')
            this.status = Status.Failed;
            this.errorMsg = e.response?.data?.errors[0] || e.message;
            if (e?.response?.status === 403) {
                notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
                this.navigate("/subscriptions");
            } else {
                notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
            }
        }
    }
    async navigate(target_url: string) {
        setTimeout(() => {
            if (!auth.isLoggedIn()) {
                window.location.href = '/auth/login?returnUrl=/subscriptions';
            } else {
                window.location.href = target_url || '/';
            }
        }, 3000);
    }
}

const pdfServiceStore = new PdfServiceStore();
export default pdfServiceStore;
