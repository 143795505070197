// @ts-nocheck

import { configure, makeAutoObservable } from 'mobx';
import { apiClient } from '../../shared/base-classes/apiBaseClass';
import notificationState from '../../shared/mobx/notification-state';
import auth from '../../shared/mobx/auth-state';


export interface PageData {
    current_page: number;
    data: keyObj[]; // Using keyObj interface here
    next_page: number | null;
    per_page: number;
    previous_page: number | null;
    total_records: number;
}

export interface IKeyPageData {
    current_page: number;
    data: IkeyUsageObj[];
    next_page: number | null;
    per_page: number;
    previous_page: number | null;
    total_records: number;
}

export interface IkeyUsageObj {
    id: number;
    authKeyId: number;
    source: string;
    url: string;
    createdAt: string; 
    updatedAt: string; 
    deleted: boolean;
    status: "Active" | "Inactive";
    loading?: boolean;
}


export interface keyObj {
    id: number;
    userId: number;
    key: string;
    createdAt: string;
    deleted: boolean;
    status: string;
    loading?: boolean;
    updatedAt?: string;
}

enum LoadingStatus {
    Idle = 'idle',
    InProcess = 'pending',
    Completed = 'complete',
    Failed = 'failed'
}

class ApiKeyState {
    apiClient = apiClient;
    loadingStatus = LoadingStatus.Idle;
    errorMessage = '';
    pageData: PageData | null;
    keyUsageData: IKeyPageData | null;

    constructor() {
        makeAutoObservable(this);
        configure({ enforceActions: 'never' });
    }

    get keyList(): keyObj[] {
        return this.pageData?.data || [];
    }
    get usageList(): IkeyUsageObj[] {
        return  this.keyUsageData?.data || [];
    }

    get isLoading() {
        return this.loadingStatus === LoadingStatus.InProcess;
    }
    async refreshKeyList(options: { avoidClean?: boolean; noLoader?: boolean }) {
        await this.getKeyList({ avoidClean: true, noLoader: true, ...options });
        if (this.keyList.length === 0) {
            this.generateKey();
        }
    }

    async getKeyList(options: { avoidClean?: boolean; noLoader?: boolean } = { avoidClean: false }) {
        try {

            !options.avoidClean && (this.pageData = null);

            !options.noLoader && (this.loadingStatus = LoadingStatus.InProcess);
            const response = await this.apiClient.get("user-authkeys");
            this.loadingStatus = LoadingStatus.Idle;
            this.pageData = response.data;

        } catch (error) {
            this.handleError(error);
        }
    }

    async generateKey() {
        try {
            const response = await this.apiClient.post("generate-authkey/generate");
            // notificationState.addToasterSuccess(response.message || 'API Key generated successfully!');
            this.refreshKeyList();
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }
    
    async getKeyUsage(config): IKeyPageData {
        try {
            const response = await this.apiClient.get(`authKey-log-list/${this.keyList[0].id}&per_page=${config.per_page || 10}?page=${config.page}`);
            return this.keyUsageData = response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

        async getUserKeyUsage(userId,config): IKeyPageData {
        try {
            const response = await this.apiClient.get(`admin/authkey-log-list/${userId}&per_page=${config.per_page || 10}?page=${config.page}`);
            return this.keyUsageData = response.data;
        } catch (error) {
            this.handleError(error);
        }
    }
    
    async resetKey(id: number) {
        const ind = this.keyList.findIndex(k => k.id === id);
        try {
            this.pageData.data[ind].loading = true;
            const response = await this.apiClient.post(`generate-authkey/reset`);
            this.pageData.data[ind] = response.data.userAuthKey;
            notificationState.addToasterSuccess(response.message || 'API Key reset successfully!');
        } catch (error) {
            this.handleError(error);
        }
    }

    async toggleStatus(id: number) {
        const ind = this.keyList.findIndex(k => k.id === id);
        try {
            this.pageData.data[ind].loading = true;
            const response = await this.apiClient.post(`status-change-authkey`, { authId: id });
     
            this.pageData.data[ind].status =
                this.pageData.data[ind].status === "Active"
                    ? "InActive" : "Active";

            notificationState.addToasterSuccess(response.message || 'API Key status successfully!');

        } catch (error) {
            this.handleError(error);
        }
        this.pageData.data[ind].loading = false;
    }

    async  toggleUsageSourceStatus(id: number,status:Boolean) {
        const ind = this.usageList.findIndex(k => k.id === id);
        try {
            this.keyUsageData.data[ind].loading = true;
            const response = await this.apiClient.post(
                 auth.isUser ?  `status-change-authkey-log` : `admin/status-change-authkey-log`,
                { id,status });
     
            this.keyUsageData.data[ind].status =
                this.keyUsageData.data[ind].status === "Active"
                    ? "InActive" : "Active";
            notificationState.addToasterSuccess(response.message || 'API Key status successfully!');

        } catch (error) {
            this.handleError(error);
        }
        this.keyUsageData.data[ind].loading = false;
    }
  

    async delete(id: number) {
        const ind = this.keyList.findIndex(k => k.id === id);
        try {
            this.pageData.data[ind].loading = true;
            const response = await this.apiClient.post(`delete-authkey`, { authId: id });
            notificationState.addToasterSuccess(response.message || 'API Key Deleted successfully!');
            this.refreshKeyList();
        } catch (error) {
            this.handleError(error);
        }
    }

    async updateKey(keyId: number, updatedData: keyObj) {
        try {
            const response = await this.apiClient.put(`updateapikey/${keyId}`, updatedData);
            notificationState.addToasterSuccess(response.message || 'API Key updated successfully!');
        } catch (error) {
            this.handleError(error);
        }
    }

    async statusChange(newStatus: LoadingStatus) {
        this.loadingStatus = newStatus;
    }


    resetState() {
        this.pageData = null;
        this.keyUsageData = null;
        this.loadingStatus = LoadingStatus.Idle;
        this.errorMessage = '';
    }

    handleError(error: Error) {
        this.loadingStatus = LoadingStatus.Failed;
        this.errorMessage = error.message || 'Something went wrong, try again...';
        notificationState.addToasterError(this.errorMessage);
    }
}

const apiKeyState = new ApiKeyState();
export default apiKeyState;
