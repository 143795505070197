import React, { useState } from 'react';
import userState from '../../../shared/mobx/user-state';

const ContactUsSection: React.FC = () => {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    full_name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { full_name: '', email: '', subject: '', message: '' };

    if (!formData.full_name) {
      newErrors.full_name = 'Full name is required';
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Email is not valid';
      valid = false;
    }

    if (!formData.message) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const status = await userState.contactUs(formData);
        if (status) {
          setFormData({
            full_name: '',
            email: '',
            subject: '',
            message: ''
          });
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="reach-wrap" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>Reach Out</h3>
            <p>
              We’re happy to help or answer any questions about Web2pdf. Please <br />
              provide your information and we will respond as soon as possible.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    id="full_name"
                    name="full_name"
                    placeholder="Name"
                    value={formData.full_name}
                    onChange={handleInputChange}
                    required
                    disabled={isLoading}
                  />
                  {errors.full_name && <span className="error text-danger">{errors.full_name}</span>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    disabled={isLoading}
                  />
                  {errors.email && <span className="error text-danger">{errors.email}</span>}
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <textarea
                  id="message"
                  placeholder="message"
                  name="message"
                  rows={5}
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  disabled={isLoading}
                ></textarea>
                {errors.message && <span className="error text-danger">{errors.message}</span>}
              </div>
              <button type="submit" className="btn-submit" disabled={isLoading}>
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
