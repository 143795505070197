//@ts-nocheck
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import rictangle from '../../images/rictangle.png';
import subscriptionState, { ISubsPlan } from '../../../shared/mobx/subscription-state';
import { formatNumberWithDecimal } from '../../../helpers/helpers';
import Spinner from '../../../components/bootstrap/Spinner';
import auth from '../../../shared/mobx/auth-state';
const currencyMapper = {
  "USD": "$"
};

const PackagesSection = () => {
  const [activeTab, setActiveTab] = useState('monthly');
  const [isLoading, setLoading] = useState(true);
  const [monthlyPackages, setMonthlyPackages] = React.useState([]);
  const [yearlyPackages, setYearlyPackages] = React.useState([]);

  const mapPackages = React.useCallback(
    (d: ISubsPlan[], cycle: 'month' | 'year') => {
      return d
        .filter(plan => plan.billing_cycle.interval === cycle)
        .map(plan => ({
          id: plan.id,
          name: plan.name,
          features: [plan.description],
          price: `${currencyMapper[plan.unit_price.currency_code] ?? plan.unit_price.currency_code}${formatNumberWithDecimal(plan.unit_price.amount)}/${plan.billing_cycle.interval}`,
        }));
    },
    []
  );

  React.useEffect(() => {
    // Simulated API call or data fetching
    const fetchData = async () => {
      setLoading(true);
      const packageList_ = await subscriptionState.getSubscriptionPlans(false);
      const newData: ISubsPlan[] = packageList_;

      // Map packages to include icons
      const mappedMonthlyPackages = mapPackages(newData, 'month').map(pkg => ({
        ...pkg,
        icon: newData.find(plan => plan.id === pkg.id)?.icon // Fetch icon based on id
      }));

      const mappedYearlyPackages = mapPackages(newData, 'year').map(pkg => ({
        ...pkg,
        icon: newData.find(plan => plan.id === pkg.id)?.icon // Fetch icon based on id
      }));

      setMonthlyPackages(mappedMonthlyPackages);
      setYearlyPackages(mappedYearlyPackages);

      setLoading(false);
    };

    fetchData();

  }, []);





  return (
    <div className="container" id="pricing">
      <div className="packages-section">
        <h3>Find the perfect plan for your needs</h3>
        <h4>Explore Our Flexible Pricing Options</h4>
        <p>
         
Discover the plan that best fits your requirements and start converting web pages to PDFs with ease. Whether you're a <br />
casual user or need extensive features, our pricing plans offer flexibility and value to suit your needs. <br />
Choose the right plan and enjoy seamless document management today!
</p>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'monthly' ? 'active' : ''}`}
            onClick={() => setActiveTab('monthly')}
          >
            Monthly
          </button>
          <button
            className={`tab ${activeTab === 'yearly' ? 'active' : ''}`}
            onClick={() => setActiveTab('yearly')}
          >
            Yearly
          </button>
        </div>

        <div className="packages">
          {
            isLoading ? <Spinner size="4rem" color="danger" /> : (
              <div className="row">
                {(activeTab === 'monthly' ? monthlyPackages : yearlyPackages).map((pkg, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-3 ">
                    <div className="package card-stretch-full">
                      <div class="packege-header">
                        <div class="packege-price">
                          <h3>{pkg.name}</h3>
                          <p className="price">{pkg.price}</p>
                        </div>
                        {/* <img src={`/images/plan/${pkg.name}.png`} alt={pkg.name} /> */}
                        <img src={`/images/plan/${pkg.icon}`} alt={pkg.name} className="package-icon" />
                      </div>
                      <div class="packege-detail">
                        <span>Features</span>
                        <ul>
                          {pkg.features.map((feature, featureIndex) => (
                            <span dangerouslySetInnerHTML={{ __html: feature }} key={featureIndex} />
                          ))}
                        </ul>
                      </div>
                      <div class="packege-btn">
                        {
                          auth.isUser && (<button
                            className="subscribe-btn"
                            onClick={() =>
                              window.location.href = `/subscriptions`
                            }
                          >
                            Choose Plan
                          </button>)
                        }

                        {
                          auth.isGuest && (<button
                            className="subscribe-btn"
                            onClick={() =>
                              window.location.href = `/auth/sign-up?package_id=${pkg.id} `
                            }
                          >
                            Choose Plan
                          </button>)
                        }

                        {
                          auth.isAdmin && (<button
                            className="subscribe-btn"
                          >
                            Choose Plan
                          </button>)
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          }

        </div>
      </div>
    </div>
  );
};

export default observer(PackagesSection);
