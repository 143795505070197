import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import auth from "../../../shared/mobx/auth-state";
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import classNames from 'classnames';
import LoginHeader from './LoginHeader';
import useDarkMode from '../../../hooks/useDarkMode';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Logo from '../../../components/Logo';
import Checks from '../../../components/bootstrap/forms/Checks';
import style from '../../../user/styles/adminStyle.module.css';

const Signup = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { darkModeStatus } = useDarkMode();
    if (auth.isLoggedIn()) {
		auth.navigateToRole(navigate);
	}
    return (
        <PageWrapper
            isProtected={false}
            title={true ? 'Sign Up' : 'Login'}
            className={classNames({ 'bg-dark': !true, 'bg-light': true })}>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-dark' data-tour='login-page'>
                            <CardBody>
                                <div className='text-center my-5'>
                                    <Link
                                        to='/'
                                        className={classNames(
                                            'text-decoration-none  fw-bold display-2',
                                            {
                                                'text-dark': !darkModeStatus,
                                                'text-light': darkModeStatus,
                                            },
                                        )}
                                        aria-label='Facit'>
                                        <Logo width={200} />
                                    </Link>
                                </div>
                                <div
                                    className={classNames('rounded-3', {
                                        'bg-l10-dark': !darkModeStatus,
                                        'bg-dark': darkModeStatus,
                                    })}>
                                    <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
                                        <div className='col'>
                                            <Button
                                                color={darkModeStatus ? 'light' : 'dark'}
                                                isLight={true}
                                                className='rounded-1 w-100'
                                                size='lg'
                                                tag='a'
                                                to='/auth/login'
                                            // onClick={() => {
                                            // 	setSignInPassword(false);
                                            // 	settrue(!true);
                                            // }}>
                                            >
                                                Login
                                            </Button>
                                        </div>
                                        <div className='col'>
                                            <Button
                                                tag='a'
                                                color={darkModeStatus ? 'light' : 'dark'}
                                                isLight={!true}
                                                className='rounded-1 w-100'
                                                size='lg'
                                                // onClick={() => {
                                                // 	setSignInPassword(false);
                                                // 	settrue(!true);
                                                // }}>
                                                to='/auth/sign-up'
                                            >
                                                Sign Up
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <LoginHeader isNewUser={true} />
                                <Formik
                                    initialValues={{
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        password: '',
                                        confirmPassword: '',
                                        agreeToTerms: false
                                    }}
                                    validationSchema={Yup.object().shape({
                                        firstName: Yup.string()
                                            .min(3, 'First Name must be at least 3 characters')
                                            .required('First Name is required'),

                                        lastName: Yup.string()
                                            .min(3, 'Last Name must be at least 3 characters')
                                            .required('Last Name is required'),
                                        email: Yup.string().email('Email must be a valid email').required('Email is a required field'),
                                        password: Yup.string()
                                            .min(3, 'Password must be between 3 to 30 characters long')
                                            .max(30, 'Password must be between 3 to 30 characters long')
                                            .required('Password is a required field'),
                                        confirmPassword: Yup.string()
                                            .oneOf([Yup.ref('password')], 'Passwords must match')
                                            .required('Confirm Password is a required field'),
                                        agreeToTerms: Yup.bool().oneOf([true], 'You must agree to the Terms of Service and Privacy Policy'),
                                    })}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        setIsLoading(true);
                                        try {
                                             // Get the package_id and source from the URL
                                            const params = new URLSearchParams(window.location.search);
                                            const packageId = params.get('package_id');
                                            const source = params.get('source');

                                            // Add the extracted values to the values object
                                            const signupValues = {
                                                ...values,
                                                package_id: packageId || 0,  // Default to 2 if not found
                                                source: source || 'website',  // Default to 'website' if not found
                                            };
                                            const { status } = await auth.doSignUp(signupValues);

                                            if (status === 1) {
                                               setTimeout(()=>{
                                                window.location.href = '/auth/login';
                                               },2000);
                                            } else {
                                                // Handle signup failure
                                            }
                                        } catch (error) {
                                            console.error('Signup failed:', error);
                                        }
                                        setIsLoading(false);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({ values, touched, isValid, handleChange, handleBlur, isSubmitting, setErrors, errors }) => (
                                        <Form className='row g-4'>
                                            <div className='col-12'>
                                                <FormGroup id='signup-first-name' isFloating label='First Name'>
                                                    <Input
                                                        value={values.firstName}
                                                        isTouched={touched.firstName}
                                                        invalidFeedback={errors.firstName}
                                                        isValid={isValid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => {
                                                            setErrors({});
                                                        }}
                                                        type='text'
                                                        autoComplete='given-name'
                                                        name='firstName'
                                                    />
                                                </FormGroup>

                                            </div>
                                            <div className='col-12'>
                                                <FormGroup id='signup-last-name' isFloating label='Last Name'>
                                                    <Input
                                                        value={values.lastName}
                                                        isTouched={touched.lastName}
                                                        invalidFeedback={errors.lastName}
                                                        isValid={isValid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => {
                                                            setErrors({});
                                                        }}
                                                        type='text'
                                                        autoComplete='family-name'
                                                        name='lastName'
                                                    />
                                                </FormGroup>

                                            </div>
                                            <div className='col-12'>
                                                <FormGroup id='signup-email' isFloating label='Your email'>
                                                    <Input
                                                        value={values.email}
                                                        isTouched={touched.email}
                                                        invalidFeedback={errors.email}
                                                        isValid={isValid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => {
                                                            setErrors({});
                                                        }}
                                                        type='email'
                                                        autoComplete='email'
                                                        name='email'
                                                    />
                                                </FormGroup>

                                            </div>
                                            <div className='col-12'>
                                                <FormGroup id='signup-password' isFloating label='Password'>
                                                    <Input
                                                        value={values.password}
                                                        isTouched={touched.password}
                                                        invalidFeedback={errors.password}
                                                        isValid={isValid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => {
                                                            setErrors({});
                                                        }}
                                                        type='password'
                                                        autoComplete='new-password'
                                                        name='password'
                                                    />
                                                </FormGroup>

                                            </div>
                                            <div className='col-12'>
                                                <FormGroup id='signup-confirm-password' isFloating label='Confirm Password'>
                                                    <Input
                                                        value={values.confirmPassword}
                                                        isTouched={touched.confirmPassword}
                                                        invalidFeedback={errors.confirmPassword}
                                                        isValid={isValid}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => {
                                                            setErrors({});
                                                        }}
                                                        type='password'
                                                        autoComplete='new-password'
                                                        name='confirmPassword'
                                                    />
                                                </FormGroup>

                                            </div>
                                            <div className='col-12'>
                                            <Checks
                                                    id='agreeToTerms'
                                                    label='I agree to the Terms of Service and Privacy Policy'
                                                    name='agreeToTerms'
                                                    onChange={handleChange}
                                                    isTouched={touched.agreeToTerms}
                                                    invalidFeedback={errors.agreeToTerms}
                                                    isValid={isValid}
                                                    onBlur={handleBlur}
                                                    onFocus={() => {
                                                        setErrors({});
                                                    }}
                                                    checked={values.agreeToTerms}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-info w-100 py-3 mx-0 auth-btn'
                                                    disabled={isSubmitting || isLoading}>
                                                    {isSubmitting || isLoading ? "Signing Up..." : "Sign Up"}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>


                            </CardBody>
                        </Card>
                    </div>
                </div>

            </Page>
        </PageWrapper>

    );
};

export default Signup;
