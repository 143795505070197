/* eslint-disable react-hooks/rules-of-hooks */
//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { toTitleCase } from '../../../helpers/helpers';
import dayjs from 'dayjs';

import classNames from 'classnames';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import pdfServiceStore from '../../../user/mobx/pdfState';
import subscriptionState from '../../../shared/mobx/subscription-state';
import LoaderWrapper from '../../../layout/Wrapper/LoaderWrapper';
import auth from '../../../shared/mobx/auth-state';
import ConfirmationModal from '../ConfirmationModal';
import userState from '../../../shared/mobx/user-state';
import { formatNumberWithDecimal } from "../../../helpers/helpers";
import PaginationButtons from '../../../components/PaginationButtons';
const currencyMapper = {
	"USD": "$"
};
const EVENT_STATUS = {
	'trailing': "primary",
	'active': "success",
	'update': "info",
	'cancel': "danger"
};

const userPackageHistory = () => {

	const [data, setData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [perPage, setPerPage] = useState(4);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const navigate = useNavigate();
	const HandleCancel = async () => {
		setLoading(true);

		await subscriptionState.cancel();
		setConfirmationModal(false);
		setLoading(false);
		navigate('/subscriptions');
	}
	useEffect(() => {
		const fetchData = async () => {
			auth.isUser && subscriptionState.getActiveSubscriptionPlan();
		};
		fetchData();
	}, []);
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			await subscriptionState.getActiveSubscriptionPlan();
			const { data: data_, total_records } = await subscriptionState.subscriptionHistory("dummy", { page: currentPage, per_page: perPage });
			setTotalItems(total_records)
			setLoading(false);
			setData(data_);
		};
		fetchData();
	}, [currentPage]);


	return (
		<PageWrapper title="History">
			{/* <SubHeader>
				<SubHeaderLeft>
					<Icon icon='Info' className='me-2' size='2x' />
					<span className='text-muted'>
						You have <Icon icon='TaskAlt' color='success' className='mx-1' size='lg' />{' '}
						3 approved appointments and{' '}
						<Icon icon='Alarm' color='warning' className='mx-1' size='lg' /> 4 pending
						appointments for today.
					</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Popovers
						desc={
							<DatePicker
								onChange={(item) => setDate(item)}
								date={date}
								color={process.env.REACT_APP_PRIMARY_COLOR}
							/>
						}
						placement='bottom-end'
						className='mw-100'
						trigger='click'>
						<Button color={themeStatus}>
							{`${dayjs(date).startOf('weeks').format('MMM Do')} - ${dayjs(date)
								.endOf('weeks')
								.format('MMM Do')}`}
						</Button>
					</Popovers>
				</SubHeaderRight>
			</SubHeader> */}
			<Page container='fluid'>
				<ConfirmationModal
					isOpen={confirmationModal} // Use the renamed state variable
					setIsOpen={setConfirmationModal} // Use the updated setter function
					loading={loading}
					title="Confirmation"
					message="Are you sure you want to cancel?"
					handleAction={HandleCancel}
				/>
				<div className='row'>
					<div className='col-12'>
						<div className='display-6 plan-heading py-3'>My Subscription</div>
					</div>
					<div className='col-md-4 history-packege'>
						<Card>
							<CardHeader className='text-center'>
								<CardLabel iconColor='info'>
									<CardTitle tag='div' className='h4 text-center'>
										Current Subscription
									</CardTitle>
								</CardLabel>
							</CardHeader>
							{subscriptionState.hasSubscription ? (
								<>
									<CardBody>
										<div className='row g-3'>

											<div className='col-12 application-history'>
												<div className='col py-1 pt-0'>
													<h6 className='display-6'>{subscriptionState._userSubscription.package_name}</h6>
												</div>
												<h6 className='display-5'>
													<span className='display-6'>{currencyMapper[subscriptionState._userSubscription.currency_code] ?? "$"}</span>{subscriptionState._userSubscription.package_price}
													<span className='display-6'>/  {subscriptionState._userSubscription.billing_cycle}</span>
												</h6>
												<table className='w-100'>
													<tbody>
														<tr>

															<td >Renewal Date -	{dayjs(subscriptionState._userSubscription.expiryDate).format('MM-DD-YYYY')}</td>
														</tr>
														<tr>

															<td > Activation Date -	{dayjs(subscriptionState._userSubscription.activationDate).format('MM-DD-YYYY')}</td>
														</tr>
														<tr>

															<td >Payment Date -	{dayjs(subscriptionState._userSubscription.paid_at).format('MM-DD-YYYY')}</td>
														</tr>
														{/* <tr>
															<td className='font-weight-bold'><strong>Trail End</strong></td>
															<td className='text-end'>	{dayjs(subscriptionState._userSubscription.trialEndDate).format('MM-DD-YYYY')}</td>
														</tr> */}
													</tbody>
												</table>


											</div>
										</div>
									</CardBody>
									<CardFooter>
										<Button
											onClick={() => setConfirmationModal(true)}
											 isLight size='lg' className='w-100 auth-btn'>
											Cancel Plan
										</Button>
									</CardFooter>
								</>
							) : (

								<CardBody>
									<div className='row g-3'>
										<div className='col-12'>
											<div className='col text-center py-4'>
												<h4 className='fw-bold'>
													No Active Subscription
												</h4>
											</div>
										</div>
									</div>
								</CardBody>
							)}
						</Card>
					</div>

					<div className='col-sm'>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel iconColor='info'>
									<CardTitle tag='div' className='h5 text-center'>
										Subscription History
									</CardTitle>
								</CardLabel>
								{/* <CardActions>
									<Button
										color='info'
										icon='CloudDownload'
										isLight
										tag='a'
										to='/somefile.txt'
										target='_blank'
										download>
										Export
									</Button>
								</CardActions> */}
							</CardHeader>
							<CardBody className='table-responsive'>
								<LoaderWrapper isLoading={loading} data={data}>
									<table className='table table-modern'>
										<thead>
											<tr>
												{/* <td aria-labelledby='Image' style={{ width: 60 }} /> */}
												<th>Name</th>
												<th>Created at</th>
												<th>End Date</th>
												<th class="text-center">Status</th>
												{/* <th className='text-center'>Action</th> */}
												{/* <td aria-labelledby='Actions' /> */}
											</tr>
										</thead>
										<tbody>
											{data?.map((item) => (
												<tr key={item.id}>
													{/* <td>
													<Button
														isLight
														color={item.status.color}
														icon='User'
														onClick={handleUpcomingDetails}
														aria-label='More info'
													/>
												</td> */}
													<td>
														<div className='d-flex'>
															{/* <div className='flex-shrink-0'>
															<img
																srcSet={item.assigned.srcSet}
																src={item.assigned.src}
																alt={item.assigned.name}
																width='36'
																height='36'
																className={classNames(
																	`bg-l${
																		false ? 'o' : ''
																	}25-${item.assigned.color}`,
																	'rounded-circle',
																)}
															/>
														</div> */}
															<div className='flex-grow-1  d-flex align-items-center'>
																{item.package_name}
															</div>
														</div>
													</td>
													<td>
														<div className='d-flex'>
															<div className='flex-grow-1  d-flex align-items-center'>
																{dayjs(item.createdAt).format('MM-DD-YYYY')}
															</div>
														</div>
													</td>

													<td>
														<div className='d-flex'>
															<div className='flex-grow-1  d-flex align-items-center'>
																{dayjs(item.expiryDate || item.trialEndDate).format('MM-DD-YYYY')}

															</div>
														</div>
													</td>
													<td class="text-center">
														{/* <span className='bad'>
															{item.status}
														</span> */}

														<Button
															isLink
															hoverShadow='none'
															isDisable={true}
															color={EVENT_STATUS[item.status] ?? 'warning'}
															icon='Circle'
															className='text-nowrap'>
															{toTitleCase(item.status)}
														</Button>
													</td>

													{/* <td className='text-center'>
														{
															subscriptionState._userSubscription?.plan_id === item.plan_id && item.status === "active" &&
															<Button
																color='danger'
																icon='Cancel'

																onClick={HandleCancel}>
																Cancel
															</Button>
														}

													</td> */}
												</tr>
											))}
										</tbody>
									</table>
								</LoaderWrapper>
							</CardBody>
							<PaginationButtons
								label='items'
								setCurrentPage={setCurrentPage}
								totalItems={totalItems}
								perPage={perPage}
								setPerPage={setPerPage}
								currentPage={currentPage}
							/>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default userPackageHistory;
