//@ts-nocheck
// src/components/ViewContactUsModal.tsx
import React from 'react';
import Modal from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
  CardSubTitle,
} from '../../../components/bootstrap/Card';

const ViewContactUsModal = ({ isOpen, onClose, contactMessages = [] }) => {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onClose}
      titleId="View Contact Messages"
      isStaticBackdrop={true}
      isCentered={true}
      isAnimation={true}
      title="View Contact Messages"
      onClose={onClose}
      size={"lg"} // Larger size for modal
    >
      <>
        <CardHeader>
          <CardLabel icon="Mail" iconColor="info">
            <CardTitle tag="div" className="h5">
              Contact Us Messages
            </CardTitle>
            <CardSubTitle tag="div" className="h6">
              View details of the selected message
            </CardSubTitle>
          </CardLabel>
        </CardHeader>
        <CardBody style={{ marginBottom: '0px', paddingBottom: 0 }}>
          {contactMessages.length > 0 ? (
            contactMessages.map((message, index) => (
              <div key={index}>
                {/* Section 1: Name, Email, Received Date */}
                <div className="mb-4">
                  <p style={{ color: 'black' }}><strong>Name:</strong> {message.full_name}</p>
                  <p style={{ color: 'black' }}><strong>Email:</strong> {message.email}</p>
                  <p style={{ color: 'black' }}><strong>Received Date:</strong> {new Date(message.receivedDate).toLocaleDateString()}</p>
                </div>
                {/* Section 2: Message */}
                <div>
                  <label><strong>Message:</strong></label>
                  <div
                    style={{
                      padding: '10px',
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                      minHeight: '100px',
                      whiteSpace: 'pre-wrap', // Preserve newlines and spaces
                      wordWrap: 'break-word', // Allow word wrapping
                      backgroundColor: '#f9f9f9', // Light background for message box
                      maxHeight: '300px', // Maximum height for message box
                      overflowY: 'auto', // Scrollable if content exceeds max height
                    }}
                  >
                    {message.message}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              No messages found.
            </div>
          )}
        </CardBody>
        <CardActions className="text-end  " >

          <Button
            type="button"
            color="primary"
            style={{
              
              width: '100px',
              padding: '8px'
            }}

            onClick={() => onClose(false)}
            className="m-3 me-4 auth-btn"
          >
            Close
          </Button>
        </CardActions>
      </>
    </Modal>
  );
};

export default ViewContactUsModal;
