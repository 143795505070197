import React from 'react';

import footerLogo from '../images/f-logo.png';
import { copyRightText } from '../../helpers/helpers';


const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <a href="#" className="f-logo">
                <img src="images/logo.svg" className="img-responsive" />
              </a>
              <p>Transforming Web Content into <br />
                Portable, Professional Documents</p>
            </div>
            <div className="col-md-5">
              <div className="footer-links">
              <ul>
                  <li><a href="/privacy-policy" target='_blank'>Privacy Policy</a></li>
                  <li><a href="/cookie-policy" target='_blank'>Cookie Policy</a></li>
                  <li><a href="/terms-of-use" target='_blank'>Terms of Use</a></li>
                </ul>
               

                <ul>
                  <li><a href="/#pricing">Pricing</a></li>
                  <li><a href="/#faq">FAQ</a></li>
                  <li><a href="/#contact">Contact</a></li>
                </ul>
                <ul>
                  <li><a href="/#contact">About</a></li>
                  <li><a href="/#benefit">Benefits</a></li>
                  <li><a href="/#reviews">Reviews</a></li>
                </ul>
              
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <p>{copyRightText()}</p>
            </div>
            <div className="col-md-6">
              <a href="#up" className="up-arrow"><img src="images/arrow-up.png" className="img-responsive" /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
