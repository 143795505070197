//@ts-nocheck

import { FC, useEffect, useLayoutEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Breadcrumb from "../../../components/bootstrap/Breadcrumb";
import subscriptionState, {
  ISubsPlan,
} from "../../../shared/mobx/subscription-state";

import Icon from "../../../components/icon/Icon";
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import PaddlePay from "../../../components/payment/PaddlePay";
import { Paddle } from "@paddle/paddle-js";
import { formatNumberWithDecimal } from "../../../helpers/helpers";
import auth from "../../../shared/mobx/auth-state";
import { irBlack } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface IProps {
  packageData: ISubsPlan;
  paddle: Paddle;
}

const currencyMapper = {
  USD: "$",
};

const pckgInfo = {
  Basic: {
    Image: "/images/plan/Basic.png",
    color: "primary",
    borderSize: 3,
    shadow: "lg",
  },
  Professional: {
    // icon: "Maps Home Work",
    Image: "/images/plan/Professional.png",
    borderSize: 3,
    shadow: "lg",
    color: "primary",
  },
  Corporate: {
    // icon: "CustomFactory",
    Image: "/images/plan/Corporate.png",
    borderSize: 3,
    shadow: "lg",
    color: "primary",
  },
};

const PackageCard: FC<IProps> = ({ packageData, paddle }) => {
  const [activePackageId, setActivePackageId] = useState<string | null>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(packageData.id === activePackageId);
    const fetchData = async () => {
      const planId = await subscriptionState.getActiveSubscriptionPlan();
      setActivePackageId(planId?.plan_id);
    };

    fetchData();
  }, [subscriptionState, packageData.id, activePackageId]);

  const updateIsActive = (priceId) => {
    // Remove the active class from the currently active card
    const activeCard = document.querySelector(
      ".card-stretch-full.border.border-3.border-primary"
    );
    if (activeCard) {
      activeCard.classList.remove("border", "border-3", "border-primary");
    }
    setTimeout(() => {
      setActivePackageId(priceId);
    }, 2000);

    //  setIsActive(true);
  };

  return (
    <Card
      stretch
      borderSize={pckgInfo[packageData.name]?.borderSize}
      borderColor={isActive ? pckgInfo[packageData.name]?.color : "transparent"}
      style={{ paddingBottom: "1rem" }}
    >
      <div className="package">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-12 ">
            <div className="package card-stretch-full">
              <div class="packege-header">
                <div class="packege-price">
                  <h3 className="text-white">{packageData.name}</h3>
                  <p className="price">
                    <span>
                      {currencyMapper[packageData.unit_price.currency_code] ??
                        packageData.unit_price.currency_code}{" "}
                      {formatNumberWithDecimal(packageData.unit_price.amount)}/{" "}
                      {packageData.billing_cycle?.interval
                        ? packageData.billing_cycle?.interval
                        : ""}
                    </span>
                  </p>
                </div>
                <img
                  src={`/images/plan/${packageData.icon}`}
                  alt={packageData.icon}
                  className="package-icon"
                />
              </div>
              <div class="packege-detail">
                <span>Features</span>
                <p
                  className="mx-3"
                  dangerouslySetInnerHTML={{ __html: packageData.description }}
                ></p>
              </div>
            </div>
            <div style={{ marginTop: "-3rem" }}>
              <PaddlePay
                updateIsActive={updateIsActive}
                paddle={paddle}
                priceId={packageData.id}
                packagePrice={packageData.unit_price.amount}
                {...(auth.currentUser.selected_plan !== null &&
                auth.currentUser.customer_id === null
                  ? {
                      selected_plan: true,
                      package_id: auth.currentUser.selected_plan,
                    }
                  : {})}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PackageCard;
