/* eslint-disable eqeqeq */
// @ts-nocheck
import { configure, makeAutoObservable } from "mobx";
import { apiClient } from "../base-classes/apiBaseClass";
import notificationState from "./notification-state";
import auth from "./auth-state";

enum UserRoles {
    Admin = "admin",
    User = "user",
}

enum LocalStorageKeys {
    User = "web2pdf-user",
}

export interface IUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    activationToken: string;
    emailVerifiedAt: string | null;
    lastLogin: string | null;
    createdAt?: string;
    updatedAt?: string;
    deleted?: boolean;
    status?: string;
    UserStateToken: string;
    role: string;
}


class UserState {
    private _userList?: IUser[] | null | undefined;

    constructor() {
        configure({ enforceActions: "never" });
        makeAutoObservable(this);
    }

    async getAdminStats(dates = {}) {
        try {
            let params = '';
            if (dates.startDate && dates.endDate) {
                params = `startDate=${dates.startDate}&endDate=${dates.endDate}`
            }
            const response = await apiClient.get("admin/admin-dashboard-stats?" + params);
            console.log(response.data);
            return response.data;
        } catch (e: any) {
            notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
        }
    }
    async getUserStats(dates = {}) {
        try {
            let params = '';
            if (dates.startDate && dates.endDate) {
                params = `startDate=${dates.startDate}&endDate=${dates.endDate}`
            }
            const response = await apiClient.get("user-dashboard-stats?" + params);
            return response.data;
        } catch (e: any) {
            notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
        }
    }
    async getuserList(config) {
        try {
            const response = await apiClient.get(`admin/users?per_page=${config.per_page || 10}&page=${config.page}`);
            return response.data;
        } catch (e: any) {
            notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
        }
    }
    async getcontactList(config) {
        try {
            const response = await apiClient.get(`admin/admin-contact-us-list?per_page=${config.per_page || 10}&page=${config.page}`);
            return response.data;
        } catch (e: any) {
            notificationState.addToasterError(this.errorMsg || 'Something went wrong, try again...');
        }
    }
    async toggleUserStatus(user: any) {
        try {
            user.loading = true;
            const status = user.status === "Active"
                ? "InActive" : "Active";
            const response = await apiClient.post(`admin/update-user-status`, { id: user.id, status });
            user.status = status;
            notificationState.addToasterSuccess(response.message || 'API Key status successfully!');

        } catch (error) {
            this.handleError(error);
        }
        user.loading = false;
    }
    async contactUsReply(email: any, full_name: any, message: any) {
        try {

            const response = await apiClient.post(`admin/contact-us-reply`, { email: email, full_name: full_name, message: message });
            notificationState.addToasterSuccess(response.message || 'Reply successfully send!');

        } catch (error) {
            this.handleError(error);
        }
        user.loading = false;
    }


    async updateProfile(data) {
        try {
            const endPoint = auth.isAdmin ? `admin/profile-update` : `update-user`;
            const response = await apiClient.post(endPoint, {
                firstName: data.firstName,
                lastName: data.lastName,
                profileImage: data.profileImage,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status) {
                auth.currentUser.firstName = data.firstName;
                auth.currentUser.lastName = data.lastName;
                auth.currentUser.profileImage = response.data?.user?.profileImage || response.data?.admin?.profileImage || auth.currentUser.profileImage;
                auth.synchronizeUserState();
                notificationState.addToasterSuccess(response.message || 'Profile updated successfully...');
            } else {
                response.errors?.forEach(m => {
                    notificationState.addToasterError(m);
                });
            }

        } catch (error) {
            this.handleError(error);
        }
    }

    async changePassword(data) {
        let status = false;
        try {
            const endPoint = auth.isAdmin ? `admin/change-password` : `change-password`;
            const response = await apiClient.post(endPoint, {
                old_password: data.currentPassword,
                new_password: data.newPassword,
            });
            if (response.status) {
                notificationState.addToasterSuccess(response.message || 'Password changed successfully...');
            } else {
                response.errors?.forEach(m => {
                    notificationState.addToasterError(m);
                });
            }
            status = response.status;;
        } catch (error) {
            this.handleError(error);
        }
        return status;
    }

    async updateUser(data) {
        try {
            const response = await apiClient.post(`admin/update-user`, {
                firstName: data.firstName,
                lastName: data.lastName,
                id: data.id
            });
            if (response.status) {
                notificationState.addToasterSuccess(response.message || 'User update successfully...');
                return true
            } else {
                response.errors?.forEach(m => {
                    notificationState.addToasterError(m);
                });
            }

        } catch (error) {
            this.handleError(error);
        }
        return false
    }

    async createUser(data) {
        try {
            const response = await apiClient.post(`admin/create-user`, {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password
            });

            if (response.status) {
                notificationState.addToasterSuccess(response.message || 'User added successfully...');
                return true
            } else {
                response.errors?.forEach(m => {
                    notificationState.addToasterError(m);
                });
            }
        } catch (error) {
            this.handleError(error);
        }
        return false
    }

    async deleteUser(id) {
        try {
            const response = await apiClient.post(`admin/delete-user`, { id });
            notificationState.addToasterSuccess(response.message || 'User has been deleted successfully...');
        } catch (error) {
            this.handleError(error);
        }
    }
    async deleteContact(id) {
        try {
            const response = await apiClient.post(`admin/admin-contact-us-msg-delete`, { id });
            notificationState.addToasterSuccess(response.message || 'Contact Inquiries has been deleted successfully...');
        } catch (error) {
            this.handleError(error);
        }
    }

    async getSingleUserDetail(id) {
        try {
            const response = await apiClient.get(`/admin/users/${id}`);
            return response?.data?.user;
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error: Error) {
        const e = error.message || 'Something went wrong, try again...';
        notificationState.addToasterError(e);
    }

    async subscribeToNewsLetter(data) {
        try {
            const response = await apiClient.post(`newsletter`, { ...data });
            if (response.status) {
                notificationState.addToasterSuccess(response.message || 'Subscription successful! Thank you for subscribing.');
            } else {
                notificationState.addToasterError(response.message || 'Subscription failed. Please try again.');
            }

            return response.status;
        } catch (error) {
            this.handleError(error);
        }
    }

    
    async contactUs(form) {
        try {
            const response = await apiClient.post(`contact-us`, { ...form });
            if (response.status) {
                notificationState.addToasterSuccess(response.message || 'User has been deleted successfully...');
            } else {
                notificationState.addToasterError(response.message || 'Something went wrong, try again...');
            }

            return response.status
        } catch (error) {
            this.handleError(error);
        }
    }
}

const userState = new UserState();

export default userState;
