//@ts-nocheck
import React, { useEffect, useState } from 'react'
import Page from '../../../layout/Page/Page';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';
import subscriptionState, { ISubsPlan } from '../../../shared/mobx/subscription-state';
import { observer } from 'mobx-react';
import useDarkMode from '../../../hooks/useDarkMode';

import LoaderWrapper from "../../../layout/Wrapper/LoaderWrapper";

import PackageList from "./PackageList";
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';

const Packages = () => {
	const [data, setData] = useState<ISubsPlan[]>([]);
	const [isLoading, setLoading] = useState(true);
	const { darkModeStatus } = useDarkMode();
	const [activeInterval, setActiveInterval] = useState('month');
	useEffect(() => {
		// Simulated API call or data fetching
		const fetchData = async () => {
			setLoading(true);
			const packageList_ = await subscriptionState.getSubscriptionPlans();
			const newData: ISubsPlan[] = packageList_;
			setData(newData);
			console.log(data);
			setLoading(false);
		};

		fetchData();
	}, []);

	const filteredData = React.useMemo(() => data.filter(d => d.billing_cycle.interval === activeInterval), [data, activeInterval])

	return (
		<PageWrapper title='Subscriptions'>
			<Page>

				<div className="row">
					<div className='col-12 my-3'>
						{/* <div className='display-6 py-0 plan-heading'>Subscription Plans</div> */}
						<SubHeader className='plan-btns'>
							<SubHeaderRight>

								<Button

									color='primary'
									isLight
									onClick={() => setActiveInterval('month')}
									isActive={activeInterval === 'month'}>
									Monthly
								</Button>
								<Button

									color='primary'
									isLight
									onClick={() => setActiveInterval('year')}
									isActive={activeInterval === 'year'}>
									Yearly
								</Button>
							</SubHeaderRight>
						</SubHeader>
					</div>

					<div className="col-12 plan-app-wrappers mb-3">
						<LoaderWrapper isLoading={isLoading} data={data}>
							<PackageList packageListData={filteredData} />
						</LoaderWrapper>
					</div>
				</div>

			</Page>
		</PageWrapper>
	);
};

export default observer(Packages);
