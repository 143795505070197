/* eslint-disable array-callback-return */
//@ts-nocheck
export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const formatNumberWithDecimal = (originalString: string, decimalPlaces = 2): string => {
	const num = Number(originalString);
	const divisor = Math.pow(10, decimalPlaces);
	const adjustedNum = num / divisor;
	return adjustedNum.toFixed(2);
};

const d = new Date()

export const copyRightText = () => <span>Copyright © {d.getFullYear()} www.web2pdf.com <br /> All rights reserved.</span>;

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const toTitleCase = (inputStr: string): string => {
	const normalizedStr = inputStr.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
	return normalizedStr
		.split(/_| /)
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
};




export const dynamicLoader = {
	styleSheets: [

		{
			id: 'bootstrap-css',
			href: 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css',
			rel: 'stylesheet',
		},
		{
			id: 'font-awesome',
			href: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
			rel: 'stylesheet',
			integrity: 'sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf',
			crossorigin: 'anonymous',
		},
		{
			id: 'user-style',
			href: "./user-style.css",
			rel: 'stylesheet',
		},
	],

	scripts: [
		// {
		// 	id: 'jquery',
		// 	src: 'https://code.jquery.com/jquery-3.3.1.slim.min.js',
		// 	integrity: 'sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo',
		// 	crossorigin: 'anonymous',
		// },
		// {
		// 	id: 'popper',
		// 	src: 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js',
		// },
		// {
		// 	id: 'bootstrap-js',
		// 	src: 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js',
		// },
	],

	addStyleSheets: function () {
		try {
			this.styleSheets.forEach(sheet => {
				if (!document.getElementById(sheet.id)) { // Check if the element already exists
					const link = document.createElement('link');
					link.id = sheet.id;
					link.href = sheet.href;
					link.rel = sheet.rel;
					if (sheet.integrity) link.integrity = sheet.integrity;
					if (sheet.crossorigin) link.setAttribute('crossorigin', sheet.crossorigin);
					document.head.appendChild(link);
				}
			});
		} catch (error) {
			console.error("Failed to add style sheets:", error);
		}
	},

	addScripts: function () {
		try {
			this.scripts.forEach(scriptInfo => {
				if (!document.getElementById(scriptInfo.id)) {
					const script = document.createElement('script');
					script.id = scriptInfo.id;
					script.src = scriptInfo.src;
					if (scriptInfo.integrity) script.integrity = scriptInfo.integrity;
					if (scriptInfo.crossorigin) script.setAttribute('crossorigin', scriptInfo.crossorigin);
					document.body.appendChild(script);
				}
			});
		} catch (error) {
			console.error("Failed to add scripts:", error);
		}
	},

	removeDynamicElements: function (elements = [...this.styleSheets, ...this.scripts]) {
		try {
			elements?.forEach(element => {
				const el = document.getElementById(element.id);
				if (el) {
					el.parentNode.removeChild(el);
				}
			});
		} catch (error) {
			console.error("Failed to remove dynamic elements:", error);
		}
	},
};


