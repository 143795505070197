//@ts-nocheck
import React, { useEffect, useLayoutEffect } from 'react';
import newImage from './images/reviews.gif'; // Ensure correct image path=
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CosmicOrbitLoader from '../components/CosmicOrbitLoader';
import AOS from 'aos';
import $ from 'jquery';
import 'slick-carousel';
import { copyRightText, dynamicLoader } from '../helpers/helpers';
import './styles.css'
import Navbar from './components/Navbar';
import WebToPdfSection from './components/sections/WebToPdfSection';
import PackagesSection from './components/sections/PackagesSection';
import { useLocation } from 'react-router-dom'; // Import useLocation
import ContactUsSection from './components/sections/ContactUSSection';
import { Helmet } from 'react-helmet'; // Import Helmet

const WebToPDF = () => {
    const [showPreloader, setShowPreloader] = React.useState(true);

    useEffect(() => {
        dynamicLoader.addScripts();
        setTimeout(() => {
            setShowPreloader(false);
        }, 500);
    });

    useLayoutEffect(() => {
        // @ts-ignore
        document.getElementsByTagName('TITLE')[0].text = 'Webs2Pdf';
    });
    const location = useLocation(); // Get the current route

    useEffect(
        () => {

            if (location.pathname === '/') {
                document.body.classList.add('home-page');
                document.body.classList.remove('modern-design');

            } else {
                document.body.classList.remove('home-page');
            }

            $(document).ready(function () {
                setTimeout(() => {
                    $(".testimonial-slider").slick({
                        dots: true,
                        infinite: true,
                        speed: 300,
                        arrows: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 0,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true,
                                },
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                },
                            },
                        ],
                    });
                }, 1000);
                // Remove the class from all slides

                // Add the class to the last active slide
            });
        }, [location.pathname]
    )
    if (showPreloader) {
        return <CosmicOrbitLoader />;
    }

    return (
        <>
            <Helmet>
                <title>Web2PDF - Convert Webpages to PDF</title>
                <meta name="description" content="Web2PDF allows you to convert web pages into PDF documents quickly and easily." />
                <meta name="keywords" content="web to pdf, convert webpage to pdf, online pdf converter" />
                <meta name="author" content="Web2PDF" />
                <meta property="og:title" content="Web2PDF - Convert Webpages to PDF" />
                <meta property="og:description" content="Effortlessly transform any URL into a PDF with Web2PDF." />
                <meta property="og:image" content="path/to/your/image.jpg" />
                <meta property="og:url" content="https://yourwebsite.com" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <span id="home" style={{ visibility: 'hidden' }}></span>
            <Navbar isIndex />
            {/* Header END */}

            {/* BODY START */}

            {/* Hero Section start */}
            {/* <div className="hero-section-wrap"  >
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                    <h1 data-aos="fade-down">Convert web to PDF</h1>
                    <img src="images/hero.svg" data-aos="zoom-in"/>
                    <p  >Convert webpage to PDF with one click!</p>
                    <p   data-aos-delay="100">Copy and paste the webpage URL and click the <a
                            href="#">GET PDF</a> button.</p>
                    <form className="search-bar"   data-aos-delay="200">
                        <div className="input-container"  >
                            <i className="fas fa-link"></i>
                            <input type="text" placeholder="www.example.com" />
                        </div>
                        <button type="button" className="options-btn"   >OPTIONS</button>
                        <button type="submit" className="pdf-btn"    data-aos-delay="100">GET PDF</button>
                    </form>
                </div>
            </div>
        </div>
    </div> */}
            <WebToPdfSection />
            <div className="container useage" >
                <div className="row">
                    <div className="col-md-12">
                        <span>
                            *Size and daily usage limitations may apply.
                        </span>
                    </div>
                </div>
            </div>
            <div className="pdfonline-wrap"  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">

                            <h2>How to convert a webpage to PDF online</h2>
                            <div className="grid-container">
                                {/* Grid Item 1 */}
                                <div className="grid-item"  >
                                    <h2>1</h2>
                                    <p>In a web browser, open the desired web page and copy the URL. Paste the URL that you want
                                        to convert into the text box.</p>
                                </div>
                                <div className="image-section">
                                    <img src="images/how-111.svg" alt="Image Description 1" />
                                </div>

                                {/* Grid Item 2 */}
                                <div className="grid-item">
                                    <h2>2</h2>
                                    <p>Click the "Options" button to change the default conversion settings. Then click the "Get
                                        PDF" button.</p>
                                </div>
                                <div className="image-section">
                                    <img src="images/how-222.svg" alt="Image Description 2" />
                                </div>

                                <div className="grid-item mobile-div">
                                    <h2>3</h2>
                                    <p>Please wait once the<br />file conversion starts.<br />The conversion will be<br />completed
                                        within a<br />few seconds.</p>
                                </div>
                                <div className="image-section mobile-div">
                                    <img src="images/how-333.svg" alt="Image Description 3" />
                                </div>
                                {/* aa */}
                                {/* Grid Item 3 (Desktop) */}
                                <div className="image-section desktop-div">
                                    <img src="images/how-333.svg" alt="Image Description 3" />
                                </div>
                                <div className="grid-item desktop-div">
                                    <h2>3</h2>
                                    <p>Please wait once the<br />file conversion starts.<br />The conversion will be<br />completed
                                        within a<br />few seconds.</p>
                                </div>

                                {/* Grid Item 4 */}
                                <div className="grid-item mobile-div">
                                    <h2>4</h2>
                                    <p>Click the download<br />button to save your<br />new PDF.</p>
                                </div>
                                <div className="image-section mobile-div">
                                    <img src="images/how-444.svg" alt="Image Description 4" />
                                </div>
                                <div className="image-section desktop-div">
                                    <img src="images/how-444.svg" alt="Image Description 4" />
                                </div>
                                <div className="grid-item desktop-div">
                                    <h2>4</h2>
                                    <p>Click the download<br />button to save your<br />new PDF.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PackagesSection />

            <div className="experience-wrap"  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h3>Experience seamless <br />conversion at your fingertips!</h3>
                            <h4>Effortlessly transform any URL into a PDF with a single
                                click.</h4>
                            <p>Welcome to Web2PDF, your all-in-one solution for converting web content into a versatile, easily shareable PDF format. Our tool is designed to meet
                                the needs of professionals, students, and casual users alike, providing a seamless and efficient way to capture and preserve online information.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chose-wrap"  >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6"  >
                            <h3>Why Choose Web2PDF?</h3>
                            <span>For Professionals</span>
                            <p>Are you a researcher, journalist, or business
                                professional needing to archive web pages for future reference? Web2PDF allows you to
                                effortlessly convert and store web content in a reliable PDF format, ensuring your important
                                information is always accessible, even offline.</p>

                            <span>For Students</span>
                            <p>Compiling resources for a project or thesis? Web2PDF <br />  simplifies the process by enabling you to quickly convert multiple URLs <br /> into organized PDFs.
                                This makes it easier to annotate, highlight, <br />and share your research with classNamemates and
                                professors.</p>

                            <span>For Everyday Users</span>
                            <p>Whether you're saving recipes, preserving articles, or
                                keeping <br />track of your favorite blogs, Web2PDF helps you maintain a<br /> personal archive of web
                                content that is easy to navigate and<br /> free from the clutter of advertisements and pop-ups.</p>
                        </div>
                        <div className="col-md-6">
                            <img src="images/why-choose.svg" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="feature-wrap"  >
                <div className="container">
                    <div className="row">
                        <h3>Key Features</h3>

                        <div className="col-md-3"  >
                            <div className="feature-box">
                                <h5>One-Click <br />Conversion</h5>
                                <p>Transform any web <br /> page into a PDF with <br /> just one click, making<br />  the process fast
                                    and<br />  straightforward.</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="feature-box">
                                <h5>Customizable <br />Options</h5>
                                <p>Choose from various<br /> settings to tailor the<br />  PDF output to your<br />  needs,<br />including
                                    page size,<br /> orientation, and more.</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="feature-box">
                                <h5>Secure and <br />Private</h5>
                                <p>Your data security is our<br /> priority. Web2PDF <br />ensures that all<br /> conversions are
                                    handled <br />with the utmost<br /> confidentiality and<br />  privacy.</p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="feature-box">
                                <h5>Multi-Platform <br />Support</h5>
                                <p>Accessible from any <br /> device, Web2PDF allows <br />  you to convert web<br />  pages to PDFs<br />
                                    whether you’re using a<br /> desktop, tablet,<br />or smartphone.</p>
                            </div>
                        </div>

                        <h4>Web2PDF is more than just a conversion tool; it's a gateway
                            to efficient and organized digital documentation.</h4>
                        <h4>Experience the convenience and power of seamless web content
                            conversion at your fingertips.</h4>
                    </div>
                </div>
            </div>

            <div className="virtual-wrap"  >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6"  >
                            <span>Did you know?</span>
                            <h3>Webpage to PDF</h3>
                            <h4>The ultimate virtual print experience</h4>
                            <p>
                                Converting a webpage to PDF is akin to digitally printing your webpage, but with added benefits.
                                By transforming your HTML files into PDFs, you create an editable, customizable virtual copy
                                that also helps save paper! The quality of PDF conversions depends heavily on the webpage's
                                coding and its printer-friendliness. If a webpage appears strange when printed, it's likely to
                                appear similarly in PDF format. However, there are solutions available. Some websites provide an
                                embedded print button that renders the page's content in a preformatted version, ensuring a more
                                consistent PDF output.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src="images/did-you-know.svg" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="benefits-wrap" id="benefit"  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Benefits of using Web2PDF</h3>
                            <p>Unlock the full potential of your web content with Web2PDF. Convert, customize, and securely save
                                <br />
                                web pages as PDFs, ensuring you have access to important information whenever you need it.
                                Explore <br />
                                the key advantages of using our tool to enhance your productivity and document management.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="benefit-box "  >
                                <div className="img-div">
                                    <img src="images/user.png" />
                                </div>
                                <div className="benefit-details">
                                    <span>User Friendly</span>
                                    <p>Designed for simplicity, ensuring a seamless<br />experience with no need for technical
                                        expertise.</p>
                                </div>
                            </div>
                            <div className="benefit-box">
                                <div className="img-div">
                                    <img src="images/fast.png" />
                                </div>
                                <div className="benefit-details">
                                    <span>Fast and Reliable</span>
                                    <p>Count on our robust servers for prompt and <br />trouble-free conversions, ensuring a
                                        seamless <br />experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="benefit-box">
                                <div className="img-div">
                                    <img src="images/quality.png" />
                                </div>
                                <div className="benefit-details">
                                    <span>High Quality Outputs</span>
                                    <p>Enjoy pristine PDFs in every conversion, ensuring <br />an exceptional viewing experience.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit-box">
                                <div className="img-div">
                                    <img src="images/benefits.png" />
                                </div>
                                <div className="benefit-details">
                                    <span>Simplify file sharing with PDFs</span>
                                    <p>A universal format accessible on computers and <br />mobile devices, ensuring easy sharing
                                        of web <br />content with others.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="user-wrap"  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" id="reviews">
                            <h3>Real User Experiences</h3>
                            <p>
                                Discover the impact Web2PDF is making through user reviews.<br />
                                Learn how it enhances productivity, organization, and overall
                                efficiency in document management.
                            </p>
                            <img src="images/reviews.svg" alt="Why Choose" className="img-responsive" />
                            <div className="testimonial-slider">
                                <div className="testimonial-item" >
                                    <div className="testimonial-content">
                                        <i className="fa-regular fa-user"></i>
                                        <p>Web2PDF has been a<br /> game-changer for my work. As a<br /> researcher, I often need to
                                            save<br /> web pages for offline reading<br /> and reference. This tool makes <br />it so easy
                                            to convert web pages<br /> to PDF with just a few clicks. It <br />has significantly
                                            improved my<br /> productivity and organization.<br /> I highly recommend it!</p>
                                        <h3>Sarah Lawrence</h3>
                                        <p>RESEARCHER</p>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <i className="fa-regular fa-user"></i>
                                        <p>I use Web2PDF daily for my <br />job, and it has made managing<br /> documents so much
                                            easier.<br /> The conversion process is quick<br /> and the quality of the PDFs is<br />
                                            excellent. Plus, I love that I don't<br /> need to download any software. <br />
                                            It's a must-have tool for anyone <br />dealing with web content<br /> regularly.</p>
                                        <h3>James Peterson</h3>
                                        <p>MARKETING MANAGER</p>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <i className="fa-regular fa-user"></i>
                                        <p>Web2PDF is a fantastic tool for<br /> anyone who needs to save web<br /> pages for later
                                            use. It is especially<br /> useful for creating printable<br /> versions of web content.
                                            The <br /> interface is user-friendly, and the<br /> service is reliable. This tool has<br />
                                            definitely increased my efficiency <br />in handling documents.</p>
                                        <h3>Emily Roberts</h3>
                                        <p>FREELANCE WRITER</p>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        <i className="fa-regular fa-user"></i>
                                        <p>Web2PDF is a fantastic tool for<br /> anyone who needs to save web<br /> pages for later
                                            use. It is especially<br /> useful for creating printable<br /> versions of web content.
                                            The <br /> interface is user-friendly, and the<br /> service is reliable. This tool has<br />
                                            definitely increased my efficiency <br />in handling documents.</p>
                                        <h3>Emily Roberts</h3>
                                        <p>FREELANCE WRITER</p>
                                    </div>
                                </div>
                                <div className="testimonial-item" data-aos-delay="400">
                                    <div className="testimonial-content">
                                        <i className="fa-regular fa-user"></i>
                                        <p>Web2PDF is a fantastic tool for<br /> anyone who needs to save web<br /> pages for later
                                            use. It is especially<br /> useful for creating printable<br /> versions of web content.
                                            The <br /> interface is user-friendly, and the<br /> service is reliable. This tool has<br />
                                            definitely increased my efficiency <br />in handling documents.</p>
                                        <h3>Emily Roberts</h3>
                                        <p>FREELANCE WRITER</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="broswer-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Supported Browsers</h3>
                            <p>Our online HTML to PDF converter seamlessly supports the latest versions of all major browsers,
                                ensuring compatibility<br />
                                with Chrome, Firefox, Edge, Safari, Opera, Internet Explorer, Vivaldi, and Brave. This ensures a
                                versatile user<br />
                                experience across different platforms and devices.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="icon-container">
                                <img src="images/chrome.png" alt="Chrome" className="icon" />
                                <img src="images/firefox.png" alt="Firefox" className="icon" />
                                <img src="images/edge.png" alt="Edge" className="icon" />
                                <img src="images/safari.png" alt="Safari" className="icon" />
                                <img src="images/opera.png" alt="Opera" className="icon" />
                                <img src="images/brave.png" alt="Brave" className="icon" />
                                <img src="images/vivaldi.png" alt="Vivaldi" className="icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            <div className="plan-wrap" data-aos-duration="1000">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 plan-details" data-aos-duration="1500">
                            <h3>Find the perfect plan for your needs</h3>
                            <h4>Explore Our Flexible Pricing Options</h4>
                            <p>Discover the plan that best fits your requirements and start converting web pages to PDFs with
                                ease. Whether you're a<br />
                                casual user or need extensive features, our pricing plans offer flexibility and value to suit
                                your needs. <br />
                                Choose the right plan and enjoy seamless document management today!</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="faq-wrap" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 faq-details">
                            <h3>Unveiling the answers to <br />
                                your common questions</h3>
                            <p>Web2pdf is your go-to tool for converting web pages into PDF documents quickly and easily.
                                Whether you're looking to save <br />
                                web content for offline reading or create printable versions of web pages, web2pdf has got you
                                covered. Here are the answers <br />
                                to some frequently asked questions to help you make the most of this powerful online tool.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center" id="faq">
                        <div className="col-md-6">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="fas fa-angles-right"></i>
                                            What is web2pdf?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Web2pdf is an online tool that allows you to convert web pages into PDF documents
                                            quickly and easily. It is designed to help users save web content in a portable and
                                            printable format.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <i className="fas fa-angles-right"></i> Is web2pdf free to use?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, web2pdf offers a free version that allows users to convert web pages to PDF
                                            without any cost. However, there may be additional features or higher usage limits
                                            available through paid plans.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <i className="fas fa-angles-right"></i>Do I need to download any software to use
                                            web2pdf?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            No, you do not need to download any software to use web2pdf. It is a web-based tool,
                                            so you can access and use it directly from your browser.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingfour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingfour" aria-expanded="false" aria-controls="collapsefour">
                                            <i className="fas fa-angles-right"></i>How do I convert a web page to a PDF using
                                            web2pdf?
                                        </button>
                                    </h2>
                                    <div id="headingfour" className="accordion-collapse collapse" aria-labelledby="headingfour"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            To convert a web page to a PDF using web2pdf, follow these steps:
                                            <ul>
                                                <li>Go to the web2pdf website.</li>
                                                <li>Enter the URL of the web page you want to convert in the provided input
                                                    field.</li>
                                                <li>Click the "Convert" button.</li>
                                                <li>Wait for the conversion to complete.</li>
                                                <li>Download the resulting PDF file to your device.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingfive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingfive" aria-expanded="false" aria-controls="collapsefive">
                                            <i className="fas fa-angles-right"></i>Can I convert multiple web pages at once?

                                        </button>
                                    </h2>
                                    <div id="headingfive" className="accordion-collapse collapse" aria-labelledby="headingfive"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Web2pdf typically allows you to convert one web page at a time. If you need to
                                            convert multiple web pages, you will need to repeat the conversion process for each
                                            URL. Some advanced or paid versions may offer batch conversion features.

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingsix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingsix" aria-expanded="false" aria-controls="collapsesix">
                                            <i className="fas fa-angles-right"></i>Are there any limits on the number of PDF
                                            conversions?
                                        </button>
                                    </h2>
                                    <div id="headingsix" className="accordion-collapse collapse" aria-labelledby="headingsix"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The free version of web2pdf may have limits on the number of PDF conversions you can
                                            perform within a certain time frame. These limits can vary, so it's best to check
                                            the website for specific details. Paid plans often offer higher or unlimited
                                            conversion limits.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingseven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingseven" aria-expanded="false" aria-controls="collapseseven">
                                            <i className="fas fa-angles-right"></i>What customization options are available for PDF
                                            conversion?
                                        </button>
                                    </h2>
                                    <div id="headingseven" className="accordion-collapse collapse" aria-labelledby="headingseven"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Web2pdf offers various customization options for PDF conversion, including:
                                            <ul>
                                                <li>Selecting the page size and orientation (e.g., A4, Letter, portrait,
                                                    landscape).
                                                </li>
                                                <li>Adjusting margins.</li>
                                                <li>Including or excluding images and links.</li>
                                                <li>Setting a header and footer.</li>
                                                <li>Configuring other advanced settings depending on the specific requirements.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingeight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingeight" aria-expanded="false" aria-controls="collapseeight">
                                            <i className="fas fa-angles-right"></i>Is web2pdf mobile-friendly?
                                        </button>
                                    </h2>
                                    <div id="headingeight" className="accordion-collapse collapse" aria-labelledby="headingeight"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, web2pdf is mobile-friendly. You can access and use the tool on your mobile
                                            device’s browser, allowing you to convert web pages to PDFs on the go.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingnine">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingnine" aria-expanded="false" aria-controls="collapsnine">
                                            <i className="fas fa-angles-right"></i>Can I use web2pdf without registering?
                                        </button>
                                    </h2>
                                    <div id="headingnine" className="accordion-collapse collapse" aria-labelledby="headingnine"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, you can use web2pdf without registering. The tool is designed to be
                                            user-friendly and accessible without the need for creating an account or signing in.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingten">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingten" aria-expanded="false" aria-controls="collapsnine">
                                            <i className="fas fa-angles-right"></i>Is the conversion process secure and private?
                                        </button>
                                    </h2>
                                    <div id="headingten" className="accordion-collapse collapse" aria-labelledby="headingten"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, the conversion process is secure and private. Web2pdf ensures that your data and web pages are handled with confidentiality and are not stored or shared with third parties.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingeleven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingeleven" aria-expanded="false" aria-controls="collapsnine">
                                            <i className="fas fa-angles-right"></i>What file formats can I convert to PDF using web2pdf?
                                        </button>
                                    </h2>
                                    <div id="headingeleven" className="accordion-collapse collapse" aria-labelledby="headingeleven"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Web2pdf primarily focuses on converting web pages to PDF format. However, some versions may offer additional file format support for converting documents to PDF.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingtwelve">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingtwelve" aria-expanded="false" aria-controls="collapsnine">
                                            <i className="fas fa-angles-right"></i>How do I contact customer support if I encounter issues?
                                        </button>
                                    </h2>
                                    <div id="headingtwelve" className="accordion-collapse collapse" aria-labelledby="headingtwelve"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            If you encounter any issues, you can contact web2pdf’s customer support through their website’s contact form or support email. Detailed contact information is typically available on the website’s support or help section.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingtherteen">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#headingtherteen" aria-expanded="false" aria-controls="collapsnine">
                                            <i className="fas fa-angles-right"></i>Is web2pdf compatible with all web browsers?
                                        </button>
                                    </h2>
                                    <div id="headingtherteen" className="accordion-collapse collapse" aria-labelledby="headingtherteen"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, web2pdf is compatible with all major web browsers, including Chrome, Firefox, Safari, and Edge, ensuring a seamless experience regardless of your preferred browser.</div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingtfourteen">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#headingtfourteen" aria-expanded="false" aria-controls="collapsnine">
                                                <i className="fas fa-angles-right"></i>Are there any restrictions on the types of web pages?
                                            </button>
                                        </h2>
                                        <div id="headingtfourteen" className="accordion-collapse collapse" aria-labelledby="headingtfourteen"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Web2pdf can convert most types of web pages, but there may be restrictions on certain dynamic or interactive web pages that rely heavily on JavaScript or other technologies that are not easily rendered in a PDF format. </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                                            <img src="images/faq.svg" className="img-responsive" />
                                        </div>
                    </div>
                </div>
</div>
                {/* <div className="reach-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3>Reach Out</h3>
                    <p>We’re happy to help or answer any questions about Web2pdf. Please <br/>
                        provide your information and we will response as soon as possible.</p>
                </div>
            </div>
            <div className="row">
                <div className="contact-form">
                    <form action="#" method="post">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="name">Name</label>
                                <input type="text" id="name" name="name" required/>
                            </div>
                            <div className="form-group">
                                <label for="email">Email</label>
                                <input type="email" id="email" name="email" required/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="subject">Subject</label>
                            <input type="text" id="subject" name="subject" required/>
                        </div>
                        <div className="form-group">
                            <label for="message">Message</label>
                            <textarea id="message" name="message" rows="5" required></textarea>
                        </div>
                        <button type="submit" className="btn-submit">
                            Send Message
                            <i className="fas fa-location-arrow"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div> */}
                <ContactUsSection />
                {/* Footer */}
                <footer>
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <a href="#" className="f-logo">
                                        <img src="images/logo.png" className="img-responsive" />
                                    </a>
                                    <p>Transforming Web Content into <br />
                                        Portable, Professional Documents</p>
                                </div>
                                <div className="col-md-5">
                                    <div className="footer-links">
                                        <ul>
                                            <li><a href="/privacy-policy" target='_blank'>Privacy Policy</a></li>
                                            <li><a href="/cookie-policy" target='_blank'>Cookie Policy</a></li>
                                            <li><a href="/terms-of-use" target='_blank'>Terms of Use</a></li>
                                        </ul>
                                        <ul>
                                            <li><a href="#contact">About</a></li>
                                            <li><a href="#benefit">Benefits</a></li>
                                            <li><a href="#reviews">Reviews</a></li>
                                        </ul>
                                        <ul>
                                            <li><a href="#pricing">Pricing</a></li>
                                            <li><a href="#faq">FAQ</a></li>
                                            <li><a href="#contact">Contact</a></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-6">
                                    <p>Copyright © 2024 www.web2pdf.com  All rights reserved.</p>
                                </div>
                                <div className="col-md-6">
                                    <a href="#up" className="up-arrow"><img src="images/tick.png" className="img-responsive" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
            );
};

            export default WebToPDF;
