// @ts-nocheck
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import userState from '../../shared/mobx/user-state';

function NewsLetter() {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setEmail('');
        setError('');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        // Add your subscription logic here
        const result = await userState.subscribeToNewsLetter({ email })
        if (result) {
            toggleModal();
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <div>
                <p onClick={toggleModal} style={{ cursor: 'pointer' }}>
                    Subscribe to our newsletter. <span style={{ color: '#32DC50', fontWeight: 700 }}>Click here</span>
                </p>
            </div>

            <Modal
                isOpen={isOpen}
                title="Subscribe to Newsletter"
                Id="subscribe-newsletter"
                setIsOpen={setIsOpen}
                size="md"
                buttonText="Close">
                <ModalHeader setIsOpen={setIsOpen}>
                    <ModalTitle Id="newsletter-modal-title">Subscribe to Newsletter</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form
                        onSubmit={handleFormSubmit}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%'
                        }}
                    >
                        <input
                            style={{
                                flexGrow: 1,
                                color: 'black',
                                padding: '10px',
                                borderRadius: '4px',
                                background: 'white',
                                border: '1px solid #ccc'
                            }}
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Enter your email"
                            required
                        />
                        <button
                            type="submit"
                            style={{
                                flexShrink: 0,
                                padding: '10px 20px',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Subscribe
                        </button>
                    </form>
                    {error && <p style={{ color: 'red', marginTop: '5px' }}>{error}</p>}

                </ModalBody>
            </Modal>
        </>
    );
}

export default observer(NewsLetter);
