
//@ts-nocheck
import React from 'react';
import Button from '../../components/bootstrap/Button';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../components/bootstrap/Modal';
import LoaderWrapper from '../../layout/Wrapper/LoaderWrapper';
// Import your modal components, Button, and LoaderWrapper as necessary

const ConfirmationModal = ({ isOpen, setIsOpen, loading, title, message, handleAction }) => {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="confirmationModal"
      isStaticBackdrop={true}
      isCentered={true}
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id={{title}}>{title}</ModalTitle>
      </ModalHeader>
      <LoaderWrapper isLoading={loading} data={[true]}>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button
            color="info"
            isOutline
            className="auth-btn"
            onClick={() => setIsOpen(false)}
          >
            No
          </Button>
          <Button onClick={handleAction} color="info" className='auth-btn'>
            Yes
          </Button>
        </ModalFooter>
      </LoaderWrapper>
    </Modal>
  );
};

export default ConfirmationModal;
