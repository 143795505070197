//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import notificationState from '../../../shared/mobx/notification-state';
import pdfServiceStore from '../../mobx/pdfState';
import { observer } from 'mobx-react';
import { DownloadFile } from '../../../shared/utils';
import ConvertionOptions from '../ConvertionOptions';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../../components/bootstrap/Modal';
import auth from '../../../shared/mobx/auth-state';
import subscriptionState from '../../../shared/mobx/subscription-state';
import { Link } from 'react-router-dom';
import PdfProgress from '../PdfProgress';
import settingsState from '../../../shared/mobx/settings-state';
const WebToPdfSection: React.FC = () => {
  const initialFormState = {
    quality: 75,
    screenSize: '1366x768',
    file_type: 'pdf',
    orientation: 'landscape',
    pageNumber: false,
    type: "current_page",
    notify: false,
    range_type: "full_page",
    range_start: 1,
    range_end: 5,
    selectedPages: [],
    format: "1440x900",
    scale: 100,
    displayHeaderFooter: false,
    headerTemplate: "Webs2pdf.com", //custome header text
    footerTemplate: "Webs2pdf.com", //custome footer text
    headerFooterColor: "#000000",
    width: "816",
    height: "1056",
    is_separate: 'separate',
    margin: {
      top: "5",
      right: "5",
      bottom: "5",
      left: "5"
    },
    watermark: false,
    pageSizeType: false,
    pageCustomWidth: 250,
    pageCustomHeight: 250,
    watermarkOptions: {
      text: "Webs2pdf.com",
      rotation: 20, //rotation send
      style: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate([DEGREE]deg)", //send
        color: "#b8b8b2",
        "font-weight": "600", //send
        "font-size": "100" //send
      }
    },
    colorLayer: "#b8b8b2",
    enableColorLayer: false
  };


  const [formState, setFormState] = useState(initialFormState);

  const [validationMsg, setValidationMsg] = useState('');
  const [isOptionOpen, toggleOptions] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [siteMapModalOpen, setSiteMapModalOpen] = useState(false);
  const [siteMapData, setSiteMapData] = useState<any>({});
  const [bulkModalOpen, setBulkDataModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("")
  // Function to open the modal
  const openbulkDataModal = () => {
    setBulkDataModalOpen(true);
  };

  // Function to close the modal
  const closebulkDataModal = () => {
    setBulkDataModalOpen(false);
  };

  // Function to handle cancel action, which also closes the modal
  const cancelbulkData = () => {
    // Any additional logic for cancelling (e.g., resetting states) can go here
    closebulkDataModal();
  };
  const [urlList, setUrlList] = useState<string[]>([]);

  const [url, setUrl] = useState<string>('');
  const handleAddUrl = () => {
    // Trim the URL to remove any surrounding white space
    let u = '';
    if (pdfServiceStore.form.target_url.trim()) {
      u = pdfServiceStore.form.target_url.trim();

      if (u.endsWith('/')) {
        u = u.slice(0, -1);
      }
      setUrl(u)
      pdfServiceStore.form.target_url = ''
    }

    const trimmedUrl = u || url.trim();

    // Check if the URL is empty
    if (trimmedUrl === '') {
      notificationState.addToasterError('Please enter a valid URL');
      return;
    }

    // Regular expression to check if URL starts with http:// or https://
    const httpRegex = /^(http:\/\/|https:\/\/)/;

    // If the URL does not start with http:// or https:\/\/, prepend https:// to it
    let validatedUrl = trimmedUrl;
    if (!httpRegex.test(trimmedUrl)) {
      validatedUrl = 'https://' + trimmedUrl;
    }

    // Regular expression for URL validation
    const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})+)(?:\/[^\s]*)?$/;

    // Check if the URL is valid
    if (!urlRegex.test(validatedUrl)) {
      notificationState.addToasterError('Please enter a valid URL');
      return;
    }

    // Check for localhost in the URL
    if (validatedUrl.includes('localhost')) {
      notificationState.addToasterError('URL should not be localhost');
      return;
    }

    // If the URL passes all validations, add it to the list
    if (!urlList.includes(validatedUrl)) {
      setUrlList([...urlList, validatedUrl]);
      setUrl(''); // Clear input field after adding
    } else {
      notificationState.addToasterError('URL already exists in the list');
    }
  };



  const [showProgressText, setShowProgressText] = useState(true);
  const [maximumPageLimit, setMaximumPageLimit] = useState(1);
  useEffect(() => {
    const fetchData = async () => {
      auth.isUser && subscriptionState.getActiveSubscriptionPlan();
      const clientSettings = await settingsState.getClientSettings();
      setMaximumPageLimit(clientSettings.pdf_limit?.maximum_pages_allowed === 'unlimited' ? 9999999 : (clientSettings.pdf_limit?.maximum_pages_allowed || 1))
    };
    fetchData();
  }, []);

  const handleStartOver = (e) => {
    e.preventDefault();
    setSiteMapData({});
    setFormState(initialFormState);
    pdfServiceStore.resetState();
  }

  const generatePdfbulkData = () => {
    if (pdfServiceStore.form.target_url.trim() === '') {
      pdfServiceStore.form.target_url = urlList[0]
    }

    // const updatedUrlList = urlList.filter((item) => item !== pdfServiceStore.form.target_url);
    // setUrlList(updatedUrlList)

    handleGenerate('generate', urlList)
    closebulkDataModal();

  }

  const handleGenerate = async (action?: string, selectedPages) => {
    if (formState.pageSizeType && (formState.pageCustomWidth < 250 || formState.pageCustomWidth > 3000 || formState.pageCustomHeight < 250 || formState.pageCustomHeight > 3000)) {
      notificationState.addToasterError('Custom width and height must be between 250 && 3000')
      return
    }
    if (pdfServiceStore.isLoading) return;
    setValidationMsg('');
    // Initial check for an empty URL
    if (pdfServiceStore.form.target_url.trim() === '') {
      notificationState.addToasterError('Please enter a valid URL');
      return;
    }

    // Regular expression to check if URL starts with http:// or https://
    const httpRegex = /^(http:\/\/|https:\/\/)/;

    // If the URL does not start with http:// or https://, prepend https:// to it
    if (!httpRegex.test(pdfServiceStore.form.target_url)) {
      pdfServiceStore.form.target_url = 'https://' + pdfServiceStore.form.target_url;
    }

    // Your existing regex for URL validation (with a slight modification to allow URLs without protocol)
    const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})+)(?:\/[^\s]*)?$/;
    if (!urlRegex.test(pdfServiceStore.form.target_url)) {
      notificationState.addToasterError('Please enter a valid URL');
      return;
    }

    // Check for localhost in the URL
    if (pdfServiceStore.form.target_url.includes('localhost')) {
      notificationState.addToasterError('URL should not be localhost');
      return;
    }

    // Convert hex color to "r: xxx, g: xxx, b: xxx" format
    const hexToRgb = (hex) => {
      let r = parseInt(hex.slice(1, 3), 16);
      let g = parseInt(hex.slice(3, 5), 16);
      let b = parseInt(hex.slice(5, 7), 16);
      return `r: ${r}, g: ${g}, b: ${b}`;
    };



    const hexToRgbaWatermark = (hex, opacity = 0.5) => {
      let r = parseInt(hex.slice(1, 3), 16);
      let g = parseInt(hex.slice(3, 5), 16);
      let b = parseInt(hex.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    // if (!formState.watermarkOptions.style) {
    //   formState.colorLayer = "";
    // }



    const headerFooterTemp = "[TEXT]";
    const options = {
      ...formState,
      watermarkOptions: { ...formState.watermarkOptions, style: { ...formState.watermarkOptions.style, color: hexToRgbaWatermark(formState.watermarkOptions.style["color"]) } },
      colorLayer: formState.enableColorLayer ? hexToRgb(formState.colorLayer) : "",
      quality: +formState.quality,
      screenSize: undefined,
      scale: formState.scale,
      orientation: undefined,
      width: formState.pageSizeType ? formState.pageCustomWidth : formState.format.split('x')[0],
      height: formState.pageSizeType ? formState.pageCustomHeight : formState.format.split('x')[1],
      headerTemplate: headerFooterTemp.replace('[TEXT]', formState.headerTemplate),
      footerTemplate: headerFooterTemp.replace('[TEXT]', formState.footerTemplate),
      margin: {
        left: +formState.margin.left,
        right: +formState.margin.right,
        top: +formState.margin.top,
        bottom: +formState.margin.bottom,
      },
    };



    delete options.enableColorLayer;
    if (options.pageSizeType) {
      options.format = `${options.pageCustomWidth}x${options.pageCustomHeight}`
    }

    if (options.type === 'bulk_pdf' && formState.file_type === 'pdf') {
      options["is_separate"] = formState.is_separate === 'separate';
      pdfServiceStore.is_separate = options["is_separate"];

    } else {
      delete options.is_separate;
      pdfServiceStore.is_separate = false;
    }
    pdfServiceStore.is_separate = options["is_separate"];


    delete options.pageSizeType;
    delete options.pageCustomWidth;
    delete options.pageCustomHeight;

    options.watermarkOptions.style.transform =
      options.watermarkOptions.style.transform.replace("[DEGREE]", options.watermarkOptions.rotation.toString());

    if (!options.watermarkOptions.style['font-size'].endsWith('px')) {
      options.watermarkOptions.style['font-size'] += "px";
    }
    if (options.range_type === "range") {
      options.pageRanges = `${options.range_start}-${options.range_end}`;
    }
    ['range_type', 'range_start', 'range_end'].forEach(k => {
      delete options[k];
    });
    toggleOptions(false);
    if (options.type === "all_pages" && action !== "generate") {
      openSiteMapModal();
      setShowProgressText(false);
      if (siteMapData?.target_url === pdfServiceStore.form.target_url) return;
      setSiteMapData({});
      const siteMapUrls = await pdfServiceStore.generateSiteMap(pdfServiceStore.form.target_url);
      if (siteMapUrls) {
        setSiteMapData({
          target_url: pdfServiceStore.form.target_url,
          urls: siteMapUrls?.map(url => ({ url, isChecked: false })) ?? []
        });
      }
    } else {
      closeSiteMapModal();
      setShowProgressText(true);
      options.selectedPages = selectedPages;
      await pdfServiceStore.convertToPdf(pdfServiceStore.form.target_url, options);
    }

  };

  const generatePdfSiteMap = () => {
    const selectedPages = siteMapData.urls
      ?.filter(page => page.isChecked)
      .map(page => page.url) ?? [];

    if (selectedPages.length < 1) {
      notificationState.addToasterError("Please select at least one page to proceed.")
      return;
    }
    handleGenerate("generate", selectedPages);
  }

  const handleBulkOpen = () => {
    setUrlList([]);
    handleAddUrl();
    openbulkDataModal()
  }


  const openPreviewModal = () => {
    setPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setPreviewModalOpen(false);
  };


  const openSiteMapModal = () => {
    setSiteMapModalOpen(true);
  };

  const closeSiteMapModal = () => {
    setSiteMapModalOpen(false);
  };

  const cancelSiteMap = () => {
    closeSiteMapModal();
  }
  const selectPage = (pageItem, selection) => {
    const { urls = [] } = siteMapData || {};
    const updatedUrls = urls.map(page => {
      if (pageItem === "select_all") {
        return { ...page, isChecked: selection };
      }
      if (!pageItem.isChecked && urls?.filter(i => i.isChecked)?.length >= maximumPageLimit) {
        return page;
      }

      return page.url === pageItem.url ? { ...page, isChecked: !pageItem.isChecked } : page;
    });
    setSiteMapData({ ...siteMapData, urls: updatedUrls });
  };


  const iframe = useMemo(() => (
    <iframe title="PDF Preview" src={pdfServiceStore?.data?.pdfUrl || pdfUrl} width="100%" className='text-center' height="500px" />
  ), [pdfServiceStore?.data?.pdfUrl || pdfUrl])

  return (
    <>
      <Modal
        isOpen={bulkModalOpen}
        onClose={closebulkDataModal}
        title="Bulk Websites"
        setIsOpen={closebulkDataModal}
        size="xl"
        Id="bulkData-model"
        buttonText="Cancel"
      >
        <ModalHeader setIsOpen={closebulkDataModal}>
          <ModalTitle id="tour-title" className="d-flex align-items-end">
            Bulk PDF
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row p-0">
            <div className="col-12 pt-2 p-3">
              <div className="input-group mb-0">
                <input
                  type="text"
                  className="form-control mb-0"
                  placeholder="Enter URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <button className="btn btn-danger mt-0 add-btn" type="button" onClick={handleAddUrl}>
                  Add
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>URL</th>
                      <th className="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {urlList.map((url, index) => (
                      <tr key={index}>
                        <td>{url}</td>
                        <td className="text-end">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => setUrlList(urlList.filter((_, i) => i !== index))}
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group text-right m-0">
                <button type="button" onClick={cancelbulkData} className="btn btn-secondary m-2">
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={urlList.length === 0}
                  onClick={() => generatePdfbulkData(urlList)}
                  className="btn btn-danger mr-2"
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={siteMapModalOpen}
        onClose={closeSiteMapModal}
        title="Site Map"
        setIsOpen={openSiteMapModal}
        size="xl"
        Id="sitemap-model"
        buttonText="Cancel">
        <ModalHeader setIsOpen={closeSiteMapModal}>
          <ModalTitle id='tour-title' className='d-flex align-items-end'>
            Site Map
          </ModalTitle>
        </ModalHeader>
        <ModalBody className='modal-scrollable p-0'>
          <div className="row p-0">
            {pdfServiceStore.isLoading ? <div className="col-12 p-5 text-center">
              Please wait... <Spinner size="sm" color="primary" />
            </div> : <>
              <div className="col-12 ">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          {
                            maximumPageLimit >= siteMapData.urls?.length && (
                              <div className="form-check">
                                <input
                                  id="isCheckedAll"
                                  className="form-check-input "
                                  type="checkbox"
                                  name="isCheckedAll"
                                  checked={siteMapData.urls?.every(item => item.isChecked)}
                                  onChange={(e) => selectPage("select_all", e.target.checked)}
                                />
                              </div>
                            )
                          }

                        </th>
                        <th>Page ({siteMapData.urls?.filter(i => i.isChecked)?.length}/{siteMapData.urls?.length})

                          {siteMapData.urls?.filter(i => i.isChecked)?.length >= maximumPageLimit && (
                            <span className='text-danger px-3' >Selection is restricted to {maximumPageLimit} pages only.</span>
                          )}


                        </th>
                        <th className='text-end'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {siteMapData?.urls?.map((page, index) => (
                        <tr key={index}>
                          <td>
                            <div className="form-check">
                              <input
                                id={`check-${index}`}
                                className="form-check-input"
                                type="checkbox"
                                checked={page.isChecked}
                                disabled={(siteMapData.urls?.filter(i => i.isChecked)?.length >= maximumPageLimit) && !page.isChecked}
                                name="isCheckedPage"
                                onChange={() => selectPage(page)}
                              />
                              <label htmlFor={`check-${index}`} className="form-check-label"></label>
                            </div>
                          </td>
                          <td role="button" onClick={() => selectPage(page)} >{page.url}</td>
                          <td className="text-end">
                            <a href={page.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Visit</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>}

          </div>
        </ModalBody>
        {!pdfServiceStore.isLoading && (
          <ModalFooter>
            <div className="row">
              <div className="col-sm-12">

                <div className="form-group text-right m-0">
                  <button type="button" onClick={cancelSiteMap} className="btn btn-secondary m-2" >Cancel</button>
                  <button type="button" disabled={!siteMapData.urls?.length} onClick={generatePdfSiteMap} className="btn btn-primary mr-2" >Generate Pdf</button>
                </div>
              </div>
            </div>
          </ModalFooter>
        )}


      </Modal>

      <Modal
        isOpen={previewModalOpen}
        onClose={closePreviewModal}
        title="Preview"
        Id="preview-pdf"
        setIsOpen={setPreviewModalOpen}
        size="xl"
        buttonText="Close PDF">
        <ModalHeader setIsOpen={setPreviewModalOpen}>
          <ModalTitle Id="preview_pdf">Preview</ModalTitle>
        </ModalHeader>

        <ModalBody className='text-center'>
          {formState.file_type === 'pdf' ? (
            <>
              {iframe}</>
          ) : (
            <div style={{ width: '100%' }} className='text-center'>
              <img style={{ maxWidth: '100%' }} src={pdfServiceStore?.data?.pdfUrl || pdfUrl} />
            </div>
          )}

        </ModalBody>
      </Modal>
      <div className="hero-section-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <h1>convert webpage to <br />
              PDF with one click!</h1>
              {/* <img src="../../images/hero.svg" data-aos="zoom-in" alt="hero" /> */}
              <p>
                Copy and paste the webpage URL and click the <a href="#">GET</a> button.
              </p>

              {pdfServiceStore.isLoading || pdfServiceStore.isGenerated ? (
                <PdfProgress
                  notify={formState.notify}
                  handleStartOver={handleStartOver}
                  conversionType={formState.type}
                  showProgressText={showProgressText}
                />
              ) : (
                <form
                  className={`search-bar ${!isOptionOpen ? 'custom-radius' : ''}`}

                // onSubmit={handleGenerate}
                >
                  <div className="input-container"  >
                    <i className="fas fa-link"></i>
                    <input
                      type="text"
                      placeholder="www.example.com"
                      name="target_url"
                      disabled={pdfServiceStore.isLoading}
                      value={pdfServiceStore.form.target_url}
                      onChange={(e) => pdfServiceStore.handleChange(e)}
                    />
                  </div>
                  <div className="search-box-btn">
                    <button
                      type="button"
                      className="options-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleOptions(!isOptionOpen);
                      }}
                    >
                      OPTIONS
                    </button>
                    <button
                      type="button"
                      className="pdf-btn"
                      onClick={handleGenerate}
                      disabled={pdfServiceStore.isLoading}
                    >
                      {pdfServiceStore.isLoading ? (
                        <Spinner size="sm" color="primary" />
                      ) : formState.type === "current_page" ? (
                        <>Get</>
                      ) : (
                        <>Scan</>
                      )}
                    </button>
                  </div>
                </form>
              )}

              {pdfServiceStore.isGenerated && pdfServiceStore.progress >= 100 && (
                <>
                  {
                    pdfServiceStore.is_separate ? (
                      <div className="pdf-options" id="conversation-options">
                        <div className="container conversion-options" style={{ padding: '0px' }}>
                          <div className="new-section">
                            <div className="row options-area">
                              <table className='text-left'>
                                <thead>
                                  <tr>
                                    <th style={{ width: '80%' }}>Target URL</th>
                                    <th className="text-center">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    pdfServiceStore.bulkSeperateResponse?.map(bsr => (
                                      <tr>
                                        <td>
                                          {bsr.targetUrl}
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="ml-2 mb-2"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setPdfUrl(bsr.pdfPath)
                                              openPreviewModal();
                                            }}
                                          >
                                            Preview
                                          </button>
                                          <button
                                            className="ml-2 mb-2"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              DownloadFile(
                                                bsr.pdfPath,
                                                pdfServiceStore.data.request_token
                                              );
                                            }}
                                          >
                                            Download
                                          </button>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                  <tr>
                                    <td colSpan={2} className='text-right'>
                                      <button className="ml-2 mb-2" onClick={handleStartOver}>
                                        Start Over
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                    ) : (
                      <div className="row mt-5">
                        <div className="col-12 text-right gen-btn">
                          <button className="ml-2 mb-2" onClick={handleStartOver}>
                            Start Over
                          </button>
                          {
                            formState.type !== "bulk_pdf" &&
                            !(formState.type === 'all_pages' && ['jpeg', 'png', 'webp'].includes(formState.file_type))
                            && (
                              <button
                                className="ml-2 mb-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openPreviewModal();
                                }}
                              >
                                Preview
                              </button>
                            )}
                          <button
                            className="ml-2 mb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              DownloadFile(
                                pdfServiceStore?.data?.pdfUrl,
                                pdfServiceStore.data.request_token
                              );
                            }}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    )
                  }
                </>

              )}

            </div>
          </div>
        </div>

      </div>

      {isOptionOpen && <ConvertionOptions
        formState={formState}
        openbulkDataModal={handleBulkOpen}
        setFormState={setFormState}
        initialFormState={initialFormState}
        handleGetPages={handleGenerate}
        toggleOptions={toggleOptions} />}
    </>

  );
};

export default observer(WebToPdfSection);
