//@ts-nocheck
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "../bootstrap/Button";
// Removed import for useDarkMode since it's not used

import { observer } from "mobx-react";
import auth from "../../shared/mobx/auth-state";
import subscriptionState from "../../shared/mobx/subscription-state";
import { Paddle } from "@paddle/paddle-js";

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../bootstrap/Modal";
import LoaderWrapper from "../../layout/Wrapper/LoaderWrapper";
import { Router } from "react-router-dom";

interface IProps {
  priceId: string | number;
  packagePrice?: number | string; // Corrected to camelCase
  paddle: Paddle;
  selected_plan?: boolean;
  package_id?: string | number;
}

const PaddlePay = (props: IProps) => {
  const [state, setState] = useState(false);
  const [loading, setloading] = useState(false);
  const [currentAction, setCurrentAction] = useState<string>("Select Plan");
  const { priceId, packagePrice, paddle, selected_plan, package_id,updateIsActive } = props;
  let customer = {
    id: auth.currentUser.customer_id,
    address: {
      id: auth.currentUser.address_id,
    },
    business: auth.currentUser.business_id
      ? {
          id: auth.currentUser.business_id,
        }
      : undefined,
  };

  const isUpgrade =
    subscriptionState.UserActiveSubscription &&
    +subscriptionState.UserActiveSubscription?.unit_price?.amount <=
      +packagePrice;
  const isDowngrade =
    subscriptionState.UserActiveSubscription &&
    +subscriptionState.UserActiveSubscription?.unit_price?.amount >
      +packagePrice;

  useEffect(() => {

    let newAction = "Select Plan";
    if (subscriptionState.UserActiveSubscription) {
      if (subscriptionState.UserActiveSubscription.id === priceId) {
        newAction = "Active";
      } else if (
        +subscriptionState.UserActiveSubscription.unit_price.amount <=
        +packagePrice
      ) {
        newAction = "Upgrade";
      } else {
        newAction = "Downgrade";
      }
    }
    
    setCurrentAction(newAction);
  }, [priceId, subscriptionState.UserActiveSubscription, packagePrice]);

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        // Your API call here
        await subscriptionState.getActiveSubscriptionPlan();
      } catch (error) {
        console.error("Error fetching subscription plan:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once on mount
  const setToActive = (priceId) => {
    updateIsActive(priceId);
  };
  const handleAction = async () => {
    setloading(true);
    try {
      let response; // Declare response at the beginning of the try block
  
      switch (currentAction) {
        case "Upgrade":
        case "Downgrade":
          response = await subscriptionState.update(priceId);
  
          // Check response status and update `isActive`
          if (response?.status) {
            console.log("reached");
          setCurrentAction("Active");
          setToActive(priceId); // Set active state
          }
          setState(false);
          break;
  
        case "Cancel":
          response = await subscriptionState.cancel();
          if (response?.status) {
            setState(false);
          }
          break;
  
        default:
          await defaultCheckout();
          break;
      }
    } catch (error) {
      console.error("Error during plan change:", error);
      // Optionally show an error message to the user here
    } finally {
      setloading(false);
    }
  };
  

  const defaultCheckout = () => {
    if (!priceId) {
      console.error('Price ID is required.');
      return;
    }
    paddle?.Checkout.open({
      items: [{ priceId: priceId.toString(), quantity: 1 }],
      customData: {
        userId: auth.currentUser!.id,
        email: auth.currentUser!.email,
      },
      customer: auth.currentUser.customer_id &&
        auth.currentUser.address_id &&
        auth.currentUser.business_id ? customer : {
        email: auth.currentUser!.email,
        name: auth.currentUser?.firstName
      }
    });
  };
  const openCheckout = () => {
    if (!priceId) {
      console.error("Price ID is required.");
      return;
    }
    paddle?.Checkout.open({
      items: [
        {
          priceId: auth.currentUser?.selected_plan
            ? auth.currentUser.selected_plan.toString()
            : priceId.toString(),
          quantity: 1,
        },
      ],
      customData: {
        userId: auth.currentUser!.id,
        email: auth.currentUser!.email,
      },
      customer:
        auth.currentUser.customer_id &&
        auth.currentUser.address_id &&
        auth.currentUser.business_id
          ? customer
          : {
              email: auth.currentUser!.email,
              name: auth.currentUser?.firstName,
            },
    });
  };


  // Automatically open checkout if selected_plan is true
  if (selected_plan && package_id && package_id !="0") {
    openCheckout();
  }

  return (
    <>
      <Modal
        isOpen={state}
        setIsOpen={setState}
        titleId="confirmationModal"
        isStaticBackdrop={true}
        isCentered={true}
        isAnimation={true}
      >
        <ModalHeader setIsOpen={setState}>
          <ModalTitle id="exampleModalLabel">Confirmation</ModalTitle>
        </ModalHeader>
        <LoaderWrapper isLoading={loading} data={[true]}>
          <ModalBody>
            Are you sure you want to {currentAction.toLowerCase()} the plan?
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              isOutline
              className="border-0"
              onClick={() => setState(false)}
              disabled={loading}
            >
              No
            </Button>
            <Button onClick={handleAction} color="info" disabled={loading}>
              {loading ? "Processing..." : "Yes"}
            </Button>
          </ModalFooter>
        </LoaderWrapper>
      </Modal>
      <Button
        onClick={() => {
          if (currentAction === "Select Plan") handleAction();
          else setState(true);
        }}
        disabled={!paddle || currentAction === "Active"}
        isLight
        className="text-uppercase w-100"
        size="lg"
        color="primary"
        style={{ borderRadius: '0', marginBottom: '-4rem' , marginLeft: '-0rem' }} // Ensuring no margin bottom is applied
      >
        {currentAction}
      </Button>
    </>
  );
};

PaddlePay.propTypes = {
  priceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  packagePrice: PropTypes.number,
  selected_plan: PropTypes.bool, // New prop to indicate if the plan is selected
  package_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // New prop for package ID
};

export default observer(PaddlePay);
